<template>
    <div id="cookie-law" v-if="!consent">
        <slot :accept="accept" />
    </div>
</template>

<script>
/**
 * CookieLaw component
 * Display the cookie consent banner and saves the user consent
 * @author Alba IT
 * @version 0.1.0
 */
export default {
    name: 'CookieLaw',
    data: function() {
        return {
            consent: false
        };
    },
    mounted: function() {
        this.consent = (localStorage.getItem('cookie.consent') === null)
                            ? false
                            : localStorage.getItem('cookie.consent');
    },
    methods: {
        accept: function() {
            this.consent = true;
            localStorage.setItem('cookie.consent', this.consent);
        }
    }
}
</script>

<style lang="scss" scoped>
#cookie-law {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.250em;
    color: #FFFFFF;
    background-color: #424851;
}
</style>
