<template>
    <div @click="$emit('click')" :title="tooltip"> <!-- Click event relay -->
        <!-- Icon with text caption -->
        <span class="icon-text" :style="cssProps" v-if="(typeof caption !== 'undefined')">
            <span class="icon" :class="getCssClasses">
                <i class="fa-icon" :class="`${prefix} fa-${icon}`">
                    <slot></slot>
                </i>
            </span>
            <span class="caption is-flex">{{ caption }}</span>
        </span>
        <!-- Simple icon -->
        <span class="icon" :class="getCssClasses" :style="cssProps" v-else>
            <i class="fa-icon" :class="`${prefix} fa-${icon}`">
                <slot></slot>
            </i>
        </span>
    </div>
</template>

<script>
/**
 * FaIcon Component
 * FontAwesome icons wrapper
 * @author Alba IT
 * @version 0.1.0
 */
export default {
    name: 'FaIcon',
    props: {
        /**
         * The FontAwesome library prefix
         * ['fas', 'far']
         */
        prefix: {
            type: String,
            default: 'fas'
        },
        /**
         * The FontAwesome icon name (without the fa- prefix)
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * The icon size
         * ['default', 'small', 'medium', 'large']
         */
        size: {
            type: String,
            default: 'default'
        },
        /**
         * The icon align (when used with <input/> tags)
         * ['left', 'right']
         */
        align: {
            type: String,
            default: undefined
        },
        /**
         * Is the icon relative ?
         */
        isRelative: {
            type: Boolean,
            default: false
        },
        /**
         * Extra CSS classes definition
         */
        cssExtraClasses: {
            type: String,
            default: ''
        },
        /**
         * The optionnal icon caption
         */
        caption: {
            type: String,
            default: undefined
        },
        /**
         * The tooltip to show on icon hover
         */
        tooltip: {
            type: String,
            default: ''
        },
        /**
         * The icon color (based on Bulma colors)
         * ['black', 'white', 'primary', 'secondary', 'tertiary', 'success', 'info', 'danger']
         */
        color: {
            type: String,
            default: '#000000'
        },
        /**
         * The icon background color
         */
        backgroundColor: {
            type: String,
            default: '#11ffee00'
        },
        /**
         * The icon color on hover
         */
        hoverColor: {
            type: String,
            default: '#000000'
        },
        /**
         * Is the icon clickable?
         */
        isClickable: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    computed: {
        /**
         * CSS dynamic variables
         */
        cssProps: function() {
            return {
                '--color': this.color,
                '--hover-color': this.hoverColor,
                '--margin': (typeof this.caption === 'undefined' && typeof this.align === 'undefined') ? '0.25em' : '0'
            };
        },
        /**
         * Generate the Bulma icon classes according to the component props
         */
        getCssClasses: function() {
            const cssClasses = [{
                'is-clickable': this.isClickable,
                'is-relative': this.isRelative,
                'is-left': this.align === 'left',
                'is-right': this.align === 'right',
                'fa-lg': this.size === 'medium',
                'fa-2x': this.size === 'large'
            }, this.getSizeClass, `icon-text`, `has-background-${this.backgroundColor}`, this.cssExtraClasses];

            return cssClasses.filter(val => val);
        },
        /**
         * Get the icon size Bulma CSS class according to the component props
         */
        getSizeClass: function() {
            if (this.size === 'default') {
                return '';
            } else {
                return `is-${this.size}`;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.icon {
    margin-right: var(--margin) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.icon.is-small {
    height: 0.25rem !important;
}*/
.icon.is-small {
    height: 0.25rem !important;
}

.caption {
    line-height: 200%;
    font-family: $font-family;
}
.fas {
    color: var(--color) !important;
}
.fas:hover {
    color: var(--hover-color) !important;
}
</style>