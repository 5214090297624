<template>
    <!-- Layout main wrapper -->
    <div id="wrapper" class="is-flex is-flex-direction-row">
        <!-- SideBar (Left menu) -->
        <!--<side-bar :class="{'is-hidden-mobile': hiddenMobile}" />-->
        <side-bar :visible="sidebarVisible" @sidebar-toggle="sidebarVisible = !sidebarVisible" />

        <!-- Main content -->
        <div id="content">
            <!-- NavBar (Top bar) -->
            <!--<nav-bar @sidebar-toggle="hiddenMobile = $event" />-->
            <nav-bar :sidebarVisible="sidebarVisible" @sidebar-toggle="sidebarVisible = !sidebarVisible" />

            <!-- Router view (actual pages) -->
            <router-view :key="$route.path" />
        </div>
        <!-- Footer -->
        <footer>
            <!-- CookieLaw (RGPD cookie consent banner) -->
            <cookie-law :buttonText="$t('navigation.cookie_law_accept')" v-slot:default="slotProps">
                <nav class="level">
                    <div class="level-left">
                        <p>
                            {{ $t("message.cookie_law_message") }}
                        </p>
                    </div>
                    <div class="level-right is-justify-content-flex-end">
                        <form-button type="button"
                            cssClass="has-text-black has-background-secondary is-rounded mx-1"
                            :caption="$t('navigation.cookie_law_accept')"
                            @click="slotProps.accept()" />
                    </div>
                </nav>
            </cookie-law>
        </footer>
    </div>
</template>

<script>
/**
 * Main layout
 * Seenjab main layout
 * @author Alba IT
 * @version 0.1.0
 */
import { isMobile } from '@/scripts/util';

import SideBar from '@/components/layout/menu/SideBar.vue';
import NavBar from '@/components/layout/menu/NavBar.vue';
import CookieLaw from '@/components/lib/CookieLaw.vue';
import FormButton from '@/components/layout/controls/form/FormButton.vue';

export default {
    name: 'Layout',
    components: {
        SideBar,
        NavBar,
        CookieLaw,
        FormButton
    },
    data: function() {
        return {
            hiddenMobile: true,
            sidebarVisible: !isMobile()
        };
    }
}
</script>
