<template>
    <!-- DropDown wrapper div -->
    <div>
        <!-- DropDown header -->
        <nav class="dropdown level is-mobile" @click="isToggle ? toggle() : openLink()">
            <div class="level-left menu-wrapper">
                <span class="icon is-medium" v-if="(icon !== '')">
                    <i :class="`menu-icon icon-${icon}`"></i>
                </span>
                <span :class="['is-size-6', {'has-text-weight-bold': open}]">{{ caption }}</span>
            </div>
            <div class="level-right">
                <span class="document-count" :class="{'badge-margin': !showCaret}" v-if="showBadge && notifications !== ''">{{ notifications }}</span>
                <sj-icon :icon="openIcon" color="white" size="small" hoverColor="#FFFFFF" v-if="showCaret" />
            </div>
        </nav>
        <!-- DropDown sub-entries -->
        <slot v-if="open"></slot>
    </div>
</template>

<script>
/**
 * DropDown component
 * Display a DropDown menu
 * @author Alba IT
 * @version 0.1.0
 */
//import FaIcon from '@/components/layout/controls/FaIcon.vue';
import SjIcon from '@/components/layout/controls/SjIcon.vue';

export default {
    name: 'MenuDropdown',
    components: {
        //FaIcon,
        SjIcon
    },
    props: {
        /**
         * The field type :
         * - drowpdown : Opens the dropdown on click
         * - link : Redirect the user to the specified url, then open the dropdown
         */
        type: {
            type: String,
            default: 'dropdown'
        },
        /**
         * The menu caption
         */
        caption: {
            type: String,
            required: true
        },
        /**
         * The caption icon
         */
        icon: {
            type: String,
            default: ''
        },
        /**
         * The "link" type url
         */
        url: {
            type: String,
            default: ''
        },
        showBadge: {
            type: Boolean,
            default: true
        },
        /**
         * The notification badge content
         */
        notifications: {
            type: [String, Number],
            default: ''
        },
        /**
         * The notification badge color
         */
        notificationsColor: {
            type: String,
            default: 'tertiary'
        },
        /**
         * Show the open/closed state caret
         */
        showCaret: {
            type: Boolean,
            default: true
        },
        /**
         * Is the dropdown open by default ?
         */
        openDefault: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            open: this.openDefault
        };
    },
    computed: {
        /**
         * Return the caret icon according to the open state
         * @returns {String}
         */
        openIcon: function() {
            return (this.open) ? 'caret-up' : 'caret-down';
        },
        /**
         * Return wether the Dropdown ca be toggled on click or not
         * @returns {Boolean}
         */
        isToggle: function() {
            return this.type === "dropdown" || this.$route.path === this.url;
        }
    },
    mounted: function() {
        this.open = this.isToggle;
    },
    methods: {
        /**
         * Toogle the dropdown open/closed
         * @returns {Void}
         */
        toggle: function() {
            this.open = !this.open;
        },
        /**
         * Open the "link" type url
         * @returns {Void}
         */
        openLink: function() {
            this.$router.push({path: this.url});
            this.open = true;
        },
        /**
         * Close the DropDown
         * @returns {Void}
         */
        close: function() {
            this.open = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.dropdown {
    z-index: 10;
    color: #FFFFFF;
}
.level {
    margin-bottom: 0.2rem !important;
}
.menu-wrapper {
    max-width: 85%;
    word-break: break-all;
}
.menu-icon {
    font-size: 20pt;
    margin-right: 5px;
}
.document-count {
    text-align: center;
    min-width: 25px;
    padding: 2px;
    margin-right: 8px;
    background-color: $theme-color-16;
    border-radius: 5px;
}
.badge-margin {
    margin-right: 28px;
}
</style>
