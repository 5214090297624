/**
 * Seenjab notifications helpers
 * @author Alba IT
 * @version 0.1.0
 * @module scripts
 */
import config from '@/config';

import store from '@/middleware/store/store';

import axios from '@/scripts/util';

/**
 * Crawl through the locally saved documents to generate the detailed notifications list
 * @returns {Array<Object>}
 */
export function getNotifications() {
    const notifications =  [];

    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/notifications/${username}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        },
        validateStatus: (status) => {
            return status === 200 || status === 403;
        }
    })
    .then((result) => {
        // If the reponse is sucessful
        if(result.status === 200) {
            // If notifications were returned
            if (result.data.data.length > 0) {
                // Loop through the list
                result.data.data.forEach((notification) => {
                    // Generate a notification and add it to the notifications list
                    notifications.push(generateSingleNotification(notification));
                });
            }
        }

        return notifications;
    });
}

/**
 * Generates a single detailed notification object
 * @param {Object} document 
 * @returns {Object} notification object
 */
function generateSingleNotification(document) {
    // Split the path and remove the 2 first "meta" levels
    //et path = document.path.split('/');
    //path.shift();
    //path.shift();
    //path = path.slice(2, path.length);

    return {
        name: decodeURI(document.doc_name),
        //userDocname: document.DOC_NAME,
        //context: path.join('/'),
        context: document.doc_path,
        url: `${document.doc_path}/${document.doc_internal_name}`,
        dateDue: document.dte_due
    };
}