/**
 * The user data store definition
 * @author Alba IT
 * @version 0.1.0
 */

// States definition
const state = () => ({
    user: {
        logged: false,
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        premium_status: false,
        locale: '',
        access_token: '',
        expires_in: '',
        refresh_token: '',
        refresh_expires_in: '',
        token_creation: '',
        quota: 0,
        used_quota: 0
    }
});

// Mutations (sync updates) definitions
const mutations = {
    setLogged(state, logged) {
        state.user.logged = logged;
    },
    setUsername(state, username) {
        state.user.username = username;
    },
    setFirstname(state, firstname) {
        state.user.firstname = firstname;
    },
    setLastname(state, lastname) {
        state.user.lastname = lastname;
    },
    setEmail(state, email) {
        state.user.email = email;
    },
    setPremiumStatus(state, premium_status) {
        state.premium_status = premium_status; 
    },
    setLocale(state, locale) {
        state.user.locale = locale;
    },
    setAccessToken(state, access_token) {
        state.user.access_token = access_token;
    },
    setExpiresIn(state, expires_in) {
        state.user.expires_in = expires_in;
    },
    setRefreshToken(state, refresh_token) {
        state.user.refresh_token = refresh_token;
    },
    setRefreshExpiresIn(state, refresh_expires_in) {
        state.user.refresh_expires_in = refresh_expires_in;
    },
    setTokenCreation(state, token_creation) {
        state.user.token_creation = token_creation;
    },
    setQuota(state, quota) {
        state.quota = quota;
    },
    setUsedQuota(state, used_quota) {
        state.used_quota = used_quota;
    } 
};

// Actions (async updates) definitions
const actions = {
    setLogged(context, data) {
        context.commit('setLogged', data.logged);
    },
    setUsername(context, data) {
        context.commit('setUsername', data.username);
    },
    setFirstname(context, data) {
        context.commit('setFirstname', data.firstname);
    },
    setLastname(context, data) {
        context.commit('setLastname', data.lastname);
    },
    setEmail(context, data) {
        context.commit('setEmail', data.email);
    },
    setPremiumStatus(context, data) {
        context.commit('setPremiumStatus', data.premium_status);
    },
    setLocale(context, data) {
        context.commit('setLocale', data.locale);
    },
    setAccessToken(context, data) {
        context.commit('setAccessToken', data.access_token);
    },
    setExpiresIn(context, data) {
        context.commit('setExpiresIn', data.expires_in);
    },
    setRefreshToken(context, data) {
        context.commit('setRefreshToken', data.refresh_token);
    },
    setRefreshExpiresIn(context, data) {
        context.commit('setRefreshExpiresIn', data.refresh_expires_in)
    },
    setTokenCreation(context, data) {
        context.commit('setTokenCreation', data.token_creation);
    },
    setQuota(context, data) {
        context.commit('setQuota', data.quota);
    },
    setUsedQuote(context, data) {
        context.commit('setUsedQuota', data.used_quota);
    }
};

// Getters definitions
const getters = {
    isLogged(state) {
        return state.user.logged;
    },
    getUsername(state) {
        return state.user.username;
    },
    getFirstname(state) {
        return state.user.firstname;
    },
    getLastname(state) {
        return state.user.lastname;
    },
    getEmail(state) {
        return state.user.email;
    },
    hasPremiumStatus(state) {
        return state.user.premium_status;
    },
    getLocale(state) {
        return state.user.locale;
    },
    getAccessToken(state) {
        return state.user.access_token;
    },
    getExpiresIn(state) {
        return state.user.expires_in;
    },
    getRefreshToken(state) {
        return state.user.refresh_token;
    },
    getRefreshExpiresIn(state) {
        return state.user.refresh_expires_in;
    },
    getTokenCreation(state) {
        return state.user.token_creation;
    },
    getQuota(state) {
        return state.quota;
    },
    getUsedQuota(state) {
        return state.used_quota;
    }
};

// Export the user module data store
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
