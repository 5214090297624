<template>
<div id="sidebar" v-if="visible">
    <aside class="menu">
        <div class="sidebar-page" v-click-outside="() => close()">
            <section class="sidebar-layout">
                <o-sidebar position="static" :fullheight="true" mobile="" :expand-on-hover="false" :reduce="false" open>
                    <router-link to="/">
                        <div class="has-text-centered my-3">
                            <img class="logo mt-4" src="../../../assets/images/logo_small.svg" width="100" height="100" alt="logo_seenjab_small" />
                        </div>
                    </router-link>
                    <section class="has-text-left py-3 px-2">
                        <h5 class="menu-label ml-2">{{ $t("message.layout.sidebar.main") }}</h5>
                        <ul class="menu-list px-2 has-text-weight-semibold">
                            <li class="is-size-6">
                                <router-link class="px-0" to="/dashboard">
                                    <sj-icon icon="home" color="white" hoverColor="#FFFFFF" size="medium" :caption="$t('message.pages.dashboard.title')" cssExtraClasses="is-align-self-center" />
                                </router-link>
                            </li>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.urgent}/Documents`" :caption="$t('message.core.documents.statuses.urgent')" icon="urgent" :notifications="documents.total.urgent" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.invoices}`})">{{ $t("message.core.documents.categories.invoices") }}</span>
                                                    <span class="document-count">{{ documents.invoices.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.insurances}`})">{{ $t("message.core.documents.categories.insurances") }}</span>
                                                    <span class="document-count">{{ documents.insurances.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.subscriptions}`})">{{ $t("message.core.documents.categories.subscriptions") }}</span>
                                                    <span class="document-count">{{ documents.subscriptions.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.taxes.base}`})">{{ $t("message.core.documents.categories.taxes.base") }}</span>
                                                    <span class="document-count">{{ documents.taxes.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.user}`})">{{ $t("message.core.documents.categories.user") }}</span>
                                                    <span class="document-count">{{ documents.user.urgent }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.todo}/Documents`" :caption="$t('message.core.documents.statuses.todo')" icon="edit" :notifications="documents.total.todo" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.invoices}`})">{{ $t("message.core.documents.categories.invoices") }}</span>
                                                    <span class="document-count">{{ documents.invoices.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.insurances}`})">{{ $t("message.core.documents.categories.insurances") }}</span>
                                                    <span class="document-count">{{ documents.insurances.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.subscriptions}`})">{{ $t("message.core.documents.categories.subscriptions") }}</span>
                                                    <span class="document-count">{{ documents.subscriptions.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.taxes.base}`})">{{ $t("message.core.documents.categories.taxes.base") }}</span>
                                                    <span class="document-count">{{ documents.taxes.todo }}</span>
                                                </div>
                                            </li>
                                             <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.user}`})">{{ $t("message.core.documents.categories.user") }}</span>
                                                    <span class="document-count">{{ documents.user.todo }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>
                        </ul>

                        <h5 class="menu-label ml-2">{{ $t("navigation.categories") }}</h5>
                        <ul class="menu-list px-2 has-text-wight-semibold">
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.invoices}`" :caption="$t('message.core.documents.categories.invoices')" :notifications="documents.invoices.total" icon="coins" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.invoices}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                    <span class="document-count">{{ documents.invoices.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.invoices}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                    <span class="document-count">{{ documents.invoices.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.invoices}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                    <span class="document-count">{{ documents.invoices.done }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.insurances}`" :caption="$t('message.core.documents.categories.insurances')" :notifications="documents.insurances.total" icon="verified" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.insurances}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                    <span class="document-count">{{ documents.insurances.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.insurances}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                    <span class="document-count">{{ documents.insurances.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.insurances}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                    <span class="document-count">{{ documents.insurances.done }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.subscriptions}`" :caption="$t('message.core.documents.categories.subscriptions')" :notifications="documents.subscriptions.total" icon="renew" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.subscriptions}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                    <span class="document-count">{{ documents.subscriptions.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.subscriptions}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                    <span class="document-count">{{ documents.subscriptions.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.subscriptions}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                    <span class="document-count">{{ documents.subscriptions.done }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.taxes.base}`" :caption="$t('message.core.documents.categories.taxes.base')" icon="money" :notifications="documents.taxes.fillings.total" notificationsColor="danger">
                                        <menu-dropdown class="ml-2 my-2" type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.taxes.fillings}`" :caption="$t('message.core.documents.categories.taxes.fillings')" :notifications="documents.taxes.fillings.total" notificationsColor="danger">
                                            <ul class="is-size-6">
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.taxes.fillings}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                        <span class="document-count">{{ documents.taxes.fillings.todo }}</span>
                                                    </div>
                                                </li>
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.taxes.fillings}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                        <span class="document-count">{{ documents.taxes.fillings.urgent }}</span>
                                                    </div>
                                                </li>
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.taxes.fillings}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                        <span class="document-count">{{ documents.taxes.fillings.urgent }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </menu-dropdown>
                                        <menu-dropdown class="ml-2 mb-2" type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.taxes.receipts}`" :caption="$t('message.core.documents.categories.taxes.receipts')" :notifications="documents.taxes.receipts.total" notificationsColor="danger">
                                            <ul class="is-size-6">
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.taxes.receipts}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                        <span class="document-count">{{ documents.taxes.receipts.todo }}</span>
                                                    </div>
                                                </li>
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.taxes.receipts}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                        <span class="document-count">{{ documents.taxes.receipts.urgent }}</span>
                                                    </div>
                                                </li>
                                                <li class="level mb-2">
                                                    <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                        <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.taxes.receipts}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                        <span class="document-count">{{ documents.taxes.receipts.done }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </menu-dropdown>
                                    </menu-dropdown>
                                </a>
                            </li>
                            <!-- Various -->
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.user}`" :caption="$t('message.core.documents.categories.user')" :notifications="documents.user.total" icon="user" notificationsColor="danger">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/${config.documents.paths.user}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                    <span class="document-count">{{ documents.user.todo }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/${config.documents.paths.user}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                    <span class="document-count">{{ documents.user.urgent }}</span>
                                                </div>
                                            </li>
                                            <li class="level mb-2">
                                                <div class="submenu is-flex is-flex-direction-row is-justify-content-space-between">
                                                    <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/${config.documents.paths.user}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                    <span class="document-count">{{ documents.user.done }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>

                            <!-- User defined first-level directories -->
                            <li v-for="(directory, index) in userDirectories" :key="index">
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/Documents/${directory.name}`" :caption="truncateName(directory.name)" :title="directory.name" icon="folder-light" :notifications="getUserDirectory(directory.name).total">
                                        <ul class="is-size-6">
                                            <li class="level mb-2">
                                                <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.todo}/Documents/${directory.name}`})">{{ $t("message.core.documents.statuses.todo") }}</span>
                                                <span class="document-count">{{ getUserDirectory(directory.name).todo }}</span>
                                            </li>
                                            <li class="level mb-2">
                                                <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.urgent}/Documents/${directory.name}`})">{{ $t("message.core.documents.statuses.urgent") }}</span>
                                                <span class="document-count">{{ getUserDirectory(directory.name).urgent }}</span>
                                            </li>
                                            <li class="level mb-2">
                                                <span class="level-left" @click="$router.push({path: `/documents/${config.documents.filters.done}/Documents/${directory.name}`})">{{ $t("message.core.documents.statuses.done") }}</span>
                                                <span class="document-count">{{ getUserDirectory(directory.name).done }}</span>
                                            </li>
                                        </ul>
                                    </menu-dropdown>
                                </a>
                            </li>

                            <!-- Trashbin -->
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/${config.documents.filters.all}/${config.documents.paths.trashbin}`" :caption="$t('message.core.documents.categories.trashbin')" :notifications="documents.trashbin.total" icon="trashbin" notificationsColor="danger" :showCaret="false" />
                                </a>
                            </li>

                            <h5 class="menu-label ml-2">{{ $t("message.layout.sidebar.settings") }}</h5>
                            <li>
                                <a class="px-0">
                                    <menu-dropdown type="link" :url="`/documents/all/Documents`" :caption="$t('message.layout.sidebar.my_directories')" icon="settings" :showCaret="false" />
                                </a>
                            </li>
                        </ul>

                        <!-- Help -->
                        <h5 class="menu-label ml-2">{{ $t("message.layout.sidebar.help") }}</h5>
                        <ul class="menu-list has-text-weight-semibold ml-2">
                            <li class="is-size-6">
                                <a class="px-0" href="mailto:support@seenjab.com">
                                    <sj-icon icon="information" color="white" hoverColor="#FFFFFF" size="medium" :caption="$t('navigation.help')" cssExtraClasses="is-align-self-center" />
                                </a>
                            </li>
                        </ul>

                        <!-- Premium -->
                        <div id="premium" class="mt-4">
                            <a class="is-flex is-flex-direction-row is-align-items-center" v-if="!premium">
                                <fa-icon icon="star" color="quaternary" hoverColor="#fc9003" />
                                <h2 class="subtitle is-size-7 is-uppercase has-text-tquaternary">
                                    <router-link to="/store"><a>{{ $t("message.layout.navbar.premium") }}</a></router-link>
                                </h2>
                            </a>

                            <a class="is-flex is-flex-direction-row is-align-items-center" v-else>
                                <h2 class="subtitle is-size-7 is-uppercase has-text-tquaternary">
                                    <a>{{ $t("message.layout.navbar.thanks") }}</a>
                                </h2>
                            </a>
                        </div>
                    </section>

                    <!--<div id="menu-footer" class="is-flex is-flex-direction-column is-justify-content-flex-end">
                        <div class="has-top-border">
                            <footer class="footer is-flex is-flex-direction-row is-justify-content-center has-text-secondary p-2">
                                <fa-icon cssExtraClasses="has-border-radius-5 mx-1" icon="cog" color="primary" hoverColor="#442178" :isClickable="true" />
                                <fa-icon cssExtraClasses="has-border-radius-5 mx-1" icon="sliders-h" color="primary" hoverColor="#442178" :isClickable="true" />
                            </footer>
                        </div>
                    </div>-->
                </o-sidebar>
            </section>
        </div>
    </aside>
</div>
</template>

<script>
/**
 * SideBar component
 * Seenjab left side menu
 * @author Alba IT
 * @version 0.1.0
 */
import config from '@/config';

import { isMobile } from '@/scripts/util';
import { generateDocumentsList/*, getDocuments, filterDocuments*/ } from '@/scripts/api/document';
import { getPremiumStatus } from '@/scripts/api/payments';

import MenuDropdown from '@/components/layout/menu/MenuDropdown.vue';
import FaIcon from '@/components/layout/controls/FaIcon.vue';
import SjIcon from '@/components/layout/controls/SjIcon.vue';

export default {
    name: 'SideBar',
    components: {
        MenuDropdown,
        FaIcon,
        SjIcon
    },
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            userDirectories: [],
            userNotifications: [],
            premium: false,
            isOpen: this.visible
        };
    },
    emits: ['sidebar-toggle'],
    computed: {
        documents: function() {
            return this.$store.getters['documents/getDocuments'];
        },
        config: () => config
    },
    /*watch: {
        userDirectories: function(newVal) {
            this.userDirectories = newVal;
        }
    },*/
    beforeMount: function() {
        this.$emitter.on('update-notifications', () => {
            this.initUserDocs();
        });
        this.$emitter.on('directory-created', () => {
            this.initUserDocs();
        });
        this.$emitter.on('directory-edited', () => {
            this.initUserDocs();
        });
    },
    mounted: function() {
        this.initUserDocs();
        getPremiumStatus()
        .then((premiumStatus) => {
            this.premium = premiumStatus;
        });
    },
    methods: {
        initUserDocs: function() {
            this.userDirectories = [];
            generateDocumentsList('all', '/Documents')
            .then((documents) => {
                documents.forEach((document) => {
                    if (document.isDirectory && !(document.name.toLowerCase() in config.documents.paths)) {
                        this.userDirectories.push(document);
                    }
                });
            });
        },
        getUserDirectory: function(name) {
            if (typeof this.documents[name] !== 'undefined') return this.documents[name];
            return {
                todo: 0,
                urgent: 0,
                done: 0,
                total: 0
            };
        },
        truncateName: function(name, length = 14) {
            let ret = name;
            if (name.length > length) ret = name.substring(0, length) + '...';
            return ret;
        },
        close: function() {
            if (this.visible && isMobile()) {
                this.$emit('sidebar-toggle', false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.sidebar-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar-layout {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.menu-label {
    color: $theme-color-15;
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 0.2rem !important;
}

.submenu {
    width: 100%;
}

#premium {
    margin: 0 auto;
    a {    
        color: $theme-color-11;
        font-size: 10pt;
    }
}

.document-count {
    text-align: center;
    min-width: 25px;
    padding: 2px;
    margin-right: 8px;
    background-color: $theme-color-16;
    border-radius: 5px;
}

.submenu .document-count {
    margin-right: 16px;
}

@media only screen and (max-width: 768px) {
    // Mobile menu
    #sidebar {
        width: 100vw;
        position: fixed;
        z-index: 50;
        animation: sidebarAnim 500ms ease;
        animation-direction: alternate;

        // Sidebar animation
        @keyframes sidebarAnim {
            0% {
                display: none;
                opacity: 0;
                width: 0;
            }
            100% {
                opacity: 1;
                display: block;
                width: 100vw;
            }
        }
    }

    .sidebar-page {
        width: 70vw;
    }

    .sublist {
        z-index: 99;
    }

    // Extend the content to 100% on mobile
    #content {
        width: 100%;
    }
}
</style>
