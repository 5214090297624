<template>
    <form-input type="text" name="search"
        :showLabel="false"
        :placeholder="$t('navigation.search')"
        icon="search"
        v-model="search" />
</template>

<script>
/**
 * SearchBar component
 * @author Alba IT
 * @version 0.1.0
 */
import FormInput from '@/components/layout/controls/form/FormInput.vue';

export default {
    name: 'SearchBar',
    components: {
        FormInput
    },
    data: function() {
        return {
            search: ''
        };
    }
}
</script>
