<template>
    <div class="field" :style="cssProps">
        <!-- Field label and tooltip -->
        <div class="is-flex is-flex-direction-row">
            <label class="label" v-if="(caption !== '')">{{ caption }}{{  mandatory ? '*' : '' }}</label>
            <div class="tooltip has-tooltip-arrow has-tooltip-multiline" :class="`has-tooltip-${tooltipDirection}`" :data-tooltip="tooltip" v-if="tooltip !== ''">
                <span class="ml-1 icon is-small is-clickable has-text-black">
                    <i class="fas fa-question-circle"></i>
                </span>
            </div>
        </div>
        <!-- Single line text field -->
        <div class="control is-expanded" :class="{'has-icons-left': (icon !== ''), 'has-icons-right': type === 'password'}" v-if="['text', 'number', 'password'].includes(type)">
            <input class="input"
                :class="[{'is-static': readonly, 'is-light': (theme === 'light'), 'is-error': errors.length > 0}, extraClasses]"
                :type="typeState" :name="name"
                :placeholder="placeholder.toUpperCase()"
                @input="update($event.target.value)"
                @blur="validate"
                :value="modelValue"
                :readonly="readonly"
                :disabled="disabled"
                :maxlength="maxlength" />
            <fa-icon :icon="icon" :color="iconColor" align="left" v-if="(icon !== '')" />
            <span class="icon is-small is-right is-clickable has-text-black" v-if="type === 'password'" @click="toggleVisibility()">
                <i class="fas" :class="{'fa-eye': typeState === 'password', 'fa-eye-slash': typeState === 'text'}"></i>
            </span>
        </div>
        <!-- Multi lines text field -->
        <div class="control is-expanded" v-else-if="(type === 'textarea')">
            <textarea class="textarea"
                :class="[{'is-light': (theme === 'light'), 'is-error': errors.length > 0}, extraClasses]"
                :name="name"
                :placeholder="placeholder.toUpperCase()"
                @input="update($event.target.value)"
                @blur="validate"
                :value="modelValue"
                :readonly="readonly"
                :disabled="disabled"
                :maxlength="maxlength"></textarea>
        </div>
        <!-- Validation error message -->
        <div class="field-label is-italic has-text-left is-size-6 is-size-7-mobile" v-for="(error, index) in errors" :key="index">
            <label class="label has-text-error">{{ error }}</label>
        </div>
    </div>
</template>

<script>
/**
 * FormInput Component
 * Wraps Bulma form input to avoid code repetition
 * @author Alba IT
 * @version 0.1.0
 */
import FaIcon from '@/components/layout/controls/FaIcon.vue';

export default {
    name: 'FormInput',
    components: {
        FaIcon
    },
    props: {
        /**
         * The field type
         * @values text, password, textarea
         */
        type: {
            type: String,
            required: true,
        },
        /**
         * The field name
         */
        name: {
            type: String,
            required: true
        },
        /**
         * The field caption
         */
        caption: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            default: ''
        },
        tooltipDirection: {
            type: String,
            default: 'top'
        },
        /**
         * The field placeholder
         */
        placeholder: {
            type: String,
            default: ''
        },
        /**
         * The FormInput styling
         * values: ['default', 'light']
         */
        theme: {
            type: String,
            default: 'normal'
        },
        /**
         * The field icon
         */
        icon: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: '#000000'
        },
        /**
         * CSS extra classes definition
         */
        extraClasses: {
            type: String,
            default: ''
        },
        /**
         * Is the field read only ?
         */
        readonly: {
            type: Boolean,
            default: false
        },
        /**
         * Is the field disabled ?
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Is the field mandatory ?
         */
        mandatory: {
            type: Boolean,
            default: false
        },
        /**
         * The maximum allowed length (default = 65535)
         */
        maxlength: {
            type: Number,
            default: 65535
        },
        /**
         * The field format for validation. Uses standard and custom tests.
         * Values : ["none", "int", "float", "date", "email", {test:test, errorMsg:errorMsg}]
         * Tests: ["regexp:<regexp>", "range:X:Y", <boolean expression>]
         */
        format: {
            type: [String, Object],
            default: "none"
        },
        /**
         * The field value
         */
        modelValue: {
            type: [String, Number]
        }
    },
    computed: {
        /**
         * CSS dynamic variables
         */
        cssProps: function() {
            return {
                '--border-color': (this.readonly) ? '#CECECE' : '#8e92aa',
            };
        }
    },
    data: function() {
        return {
            typeState: this.type,
            valid: true,
            errors: []
        };
    },
    emits: ['update:modelValue'],
    methods: {
        toggleVisibility: function() {
            this.typeState = (this.typeState === 'password') ? 'text' : 'password';
        },
        update: function(value) {
            let update = true;

            if (update) {
                this.$emit('update:modelValue', value);
            }
        },
        /**
         * Validate the field and display the validation error if necessary
         * @param {Boolean} verbose show validations errors (default = true)
         * @param {any} value the value to validate, will is this.value if undefined (default = undefined)
         * @returns {Void}
         */
        validate: function(verbose = true, value = undefined) {
            this.valid = false;
            this.errors = [];

            // If no value was provided directly, use the component state
            if (typeof value === 'undefined') value = this.modelValue;

            // Mandatory validation
            if (this.mandatory && (value === '' || typeof value === 'undefined')) {
                // Error
                this.valid = false;
                if (verbose) this.errors.push(this.$t("forminput.errors.empty_field", { field: this.caption }));
            } else {
                this.valid = true;
            }

            // Format validation
            switch (this.format) {
                // Integer
                case "int":
                    if ((Number.isNaN(Number.parseInt(value)) || this.isFloat(Number.parseFloat(value))) && typeof value !== 'undefined') {
                        // Error
                        this.valid = false;
                        if (verbose) this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.int")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                // Floating point number
                case "float":
                    if (Number.isNaN(Number.parseFloat(value)) && typeof value !== 'undefined') {
                        // Error
                        this.valid = false;
                        if (verbose) this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.float")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                // Date
                case "date":
                    if (!(new Date(value) instanceof Date) || Number.isNaN(new Date(value))) {
                        // Error
                        this.valid = false;
                        if (verbose) this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.date")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                // Email address
                case "email":
                    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-]+)*$/.test(value)) {
                        // Error
                        this.valid = false;
                        if (verbose) this.errors.push(this.$t("forminput.errors.invalid_email"));
                    } else {
                        this.valid = true;
                    }
                    break;
                case "password":
                    if (!/(?=(.*[0-9]))(?=.*[!@#$£%^&*()\\[\]{}\-_+=~´`|:;"'<>,./?§°])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,64}/.test(value)) {
                        // Error
                        this.valid = false;
                        if (verbose) this.errors.push(this.$t("forminput.errors.password_complexity"));
                    } else {
                        this.valid = true;
                    }
                    break;
                default:
                    if (this.format !== 'none') {
                        // Regular expression
                        if (this.format.test.startsWith("regexp")) {
                            const splitTest = this.format.test.split(':');
                            const regExp = new RegExp(splitTest[1], "i");

                            if (!regExp.test(value)) {
                                // Error
                                this.valid = false;
                                if (verbose) this.errors.push(this.format.errorMsg);
                            } else {
                                this.valid = true;
                            }
                        // Integers range
                        } else if (this.format.test.startsWith("range")) {
                            const splitTest = this.format.test.split(':');
                            // Parse every parameter as integers to avoid comparing strings
                            const x = Number.parseInt(splitTest[1]);
                            const y = Number.parseInt(splitTest[2]);
                            value = Number.parseInt(value);

                            this.valid = (value >= x && value <= y);
                            if (verbose && !this.valid) this.errors.push(this.format.errorMsg);
                        // Boolean expression
                        } else {
                            this.valid = this.format.test;
                            if (verbose && !this.valid) this.errors.push(this.format.errorMsg);
                        }
                    }
            }
        },
        isFloat: function(n) {
            return Number(n) === n && n % 1 !== 0;
        }
    },
    /**
     * Translations
     */
    i18n: {
        messages: {
            en: {
                forminput: {
                    errors: {
                        empty_field: "The field {field} must be filled.",
                        invalid_format: "This field format is invalid.",
                        invalid_type: "This field must contain a {type} value.",
                        invalid_email: "The email address is invalid.",
                        password_complexity: "Please enter a 8-64 characters password with an upperase letter, a number and a special character."
                    },
                    types: {
                        string: "characters string",
                        int: "integer",
                        float: "decimal number",
                        date: "date",
                        email: "email address"
                    }
                }
            },
            fr: {
                forminput: {
                    errors: {
                        empty_field: "Le champ {field} doit être rempli.",
                        invlid_format: "Le format de ce champ est invalide.",
                        invalid_type: "Ce champ doit contenir {type}.",
                        invalid_email: "L'adresse email est invalide.",
                        password_complexity: "Veuillez saisir un mot de passe de 8 à 64 caractères avec une majuscule, un chiffre et un signe."
                    },
                    types: {
                        string: "une chaîne de caractères",
                        int: "un nombre entier",
                        float: "un nombre décimal",
                        date: "une date",
                        email: "une adresse email"
                    }
                }
            },
            de: {
                forminput: {
                    errors: {
                        empty_field: "Der Feld {field} muss ausgefüllt werden",
                        invlid_format: "Das Format dieses Feldes ist ungültig",
                        invalid_type: "Dieses Feld muss {type} enthalten.",
                        invalid_email: "Die E-Mail-Adresse ist ungültig.",
                        password_complexity: "Bitte verwenden Sie ein 8-64 Zeichen langes Passwort mit einem Großbuchstaben, einer Zahl und einem Sonderzeichen."
                    },
                    types: {
                        string: "eine Zeichenkette",
                        int: "eine ganze Zahl",
                        float: "eine Dezimalzahl",
                        date: "ein Datum",
                        email: "eine E-Mail-Adresse"
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/variables.scss';

input,
textarea {
    color: #000000;
    &::placeholder {
        color:#000000;
    }
}

input::placeholder {
    color: #000000 !important;
}

.icon {
    color: #000000 !important;
}

/*.field:last-child {
    margin-bottom: 0.75rem;
}*/

.is-light/*, .is-light:hover, .is-light:active*/  {
    background-color: $background-color-field;
    box-shadow: none;
    border: 1px solid var(--border-color) !important;
    border-radius: 8px;
    
    &:focus {
        border: 2px solid $border-color-field-active !important;
    }
}
/*.is-light:focus {
    border-bottom: 2px solid var(--border-color) !important;
}*/

.is-error {
    background-color: #f5a5a5;
    //border-color: #ff1818 !important;
}
</style>