<template>
    <div class="has-text-centered" v-if="!premium">
        <router-link to="/store"><span class="is-title has-text-centered">{{ $t("message.layout.navbar.notifications_premium") }}</span></router-link>
    </div>
    
    <div v-for="(item, index) in notifications" :key="index">
        <notification class="dropdown-item px-1" :title="(typeof item.userDocname !== 'undefined') ? item.userDocname : item.name"
            tag="urgent" :path="item.context" :dteDue="item.dateDue" :url="item.url" />
        <hr class="dropdown-divider">
    </div>
</template>

<script>
/**
 * NotificationsList Component
 * Displays a notifications list
 * @author Alba IT
 * @version 0.1.0
 */
import { getPremiumStatus } from '@/scripts/api/payments';
import { getNotifications } from '@/scripts/api/notifications';

import Notification from '@/components/core/notifications/Notification.vue';

export default {
    name: 'NotificationsList',
    components: {
        Notification
    },
    props: {
        /**
         * Records number to display arbitrary limit
         */
        limit: {
            tpye: Number,
            default: 0
        }
    },
    data: function() {
        return {
            premium: false,
            notifications: []
        };
    },
    mounted: function() {
        this.$emitter.on('update-notifications', () => {
            this.loadNotifications();
        });
        this.loadNotifications();
    },
    methods: {
        loadNotifications: function() {
            getPremiumStatus()
            .then((premiumStatus) => {
                this.premium = premiumStatus;
                if (premiumStatus) {
                    return getNotifications();
                }
            })
            .then((notifications) => {
                this.notifications = notifications;
                if (this.limit > 0) {
                    this.notifications = this.notifications.slice(0, this.limit);
                }
            });
        }
    }
}
</script>