<template>
    <o-modal :active="isLoading" full-screen :can-cancel="[]">
        <div class="modal-loader sj-gradient-1 is-flex is-justify-content-center is-align-items-center">
            <div class="is-flex is-flex-direction-column">
                <img class="logo is-blinking-4 mb-4" src="../../assets/images/logo_small.svg" width="320" height="150" alt="logo_seenjab_small" />
                <span class="caption mt-4">{{ caption }}</span>
            </div>
        </div>
    </o-modal>
</template>

<script>
export default {
    name: 'ModalLoader',
    props: {
        caption: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            isLoading: false
        };
    }
}
</script>

<style lang="scss" scoped>
.modal-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 40;
}
.logo {
    max-height: none;
}
.caption {
    font-size: 20pt;
    color: #FFFFFF;
}
</style>