/**
 * Seenjab Payments module
 * @author Alba IT
 * @version 0.1.0
 * @module scripts/payments
 */
 import config from '@/config';

 import store from '@/middleware/store/store';
 
 import axios from '@/scripts/util';

 /**
  * Call the API and return an user's payment with and optional dates interval
  * @param {String} dteBeg interval begin date YYYY-MM-DD
  * @param {String} dteEnd interval end date YYYY-MM-DD
  * @returns {Object} the user payments list
  */
 export function getPayments(dteBeg = '', dteEnd = '') {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Build the path
    let endpoint = `/payments/${username}`;
    endpoint += (dteBeg !== '') ? `/${dteBeg}` : '';
    endpoint += (dteEnd !== '') ? `/${dteEnd}` : '';

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + endpoint, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => result.data.data);
 }

 /**
  * Call the API and return the user's subscription informations
  * @returns {Object} the user's subscription informations
  */
 export function getSubscription() {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/payments/${username}/subscription`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => result.data.data);
 }

 /**
  * Call the API to cancel the users subscription
  * @returns {Object} the API response
  */
 export function deleteSubscription() {
     // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.delete(config.router.url + `/payments/${username}/premiumstatus`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => result.data.data);
 }

 /**
  * Call the API and return wether the user has premium status or not
  * (This endpoint is used for purely cosmetic purposes)
  * @returns {boolan} the user premium status
  */
 export function getPremiumStatus() {
     // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/payments/${username}/premiumstatus`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => {
        return result.data.data;
    });
 }
