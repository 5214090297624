/**
 * Router middleware
 * @author Alba IT
 * @version 0.1.0
 */
import { createWebHistory, createRouter } from "vue-router";
import NProgress from 'nprogress';

import store from '@/middleware/store/store';

import { updateLocalStore } from '@/scripts/api/document';

// Routes definition
const routes = [
    {
        path: "/",
        name: 'Home',
        component: () => import('@/views/public/Home.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/about",
        name: 'About',
        component: () => import('@/views/public/About.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/register",
        name: 'Register',
        component: () => import('@/views/public/Register.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/register/wizard/:step?/:validationKey?",
        name: 'RegisterWizard',
        component: () => import('@/views/public/RegisterWizard.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/validate/:validationKey",
        name: 'AccountValidation',
        component: () => import('@/views/public/AccountValidation.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/reset/password/:resetToken?",
        name: 'PasswordReset',
        component: () => import('@/views/public/PasswordReset.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/login/:message?",
        name: 'Login',
        component: () => import('@/views/public/Login.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import('@/views/public/Maintenance.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: "/reset/pin/:resetToken?",
        name: 'PinReset',
        component: () => import('@/views/private/PinReset.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/private/Dashboard.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/store',
        name: 'Store',
        component: () => import('@/views/private/Store.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: "/profile",
        name: 'Profile',
        component: () => import('@/views/private/Profile.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: "/documents/:filter/:path*",
        name: 'Documents',
        component: () => import('@/views/private/Documents.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: "/document/:context*",
        name: 'Document',
        component: () => import('@/views/private/Document.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: "/notifications",
        name: 'Notifications',
        component: () => import('@/views/private/Notifications.vue'),
        meta: {
            auth: true
        }
    },
    // 404 fallback
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        component: () => import('@/views/public/NotFound.vue'),
    }
];
  
// Instanciate the router with the defined routes
const router = createRouter({
    history: createWebHistory(), // Use the Web History mode
    routes,
    base: process.env.VUE_APP_PUBLIC_PATH
});

/**
 * Routes preprocessing, used to check the current user login status and redirect accordingly.
 * No need to worry about users fiddling with the localStorage, the secured API endpoints require a valid access token.
 * This hook also manages the router-link loaders
 */
router.beforeEach((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    // If the target route requires authorization (to.meta.auth === true) and the user is not currently logged in
    if (to.meta.auth) {
        if (!isLogged()) {
            // Redirect to the login page
            next({
                path: '/login'
            });
        } else {
            // Attempt to update the local data before rendering the route
            // This helps updating documents counts when they are sent from the mobile app
            updateLocalStore()
            .finally(() => next());
        }
    } else {
        next();
    }
});
router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.set(0.5);
    }
    next();
});
router.afterEach(() => {
    NProgress.done();
});

// Get the current user logged in status
function isLogged() {
    return store.getters['user/isLogged']
        && typeof store.getters['user/getAccessToken'] !== 'undefined'
        && store.getters['user/getAccessToken'] !== '';
}

export default router;
