<template>
    <div class="sj-icon-wrapper" :style="cssProps" @click="$emit('click')" :title="tooltip">
        <span class="icon-text" :style="cssProps" v-if="(typeof caption !== 'undefined')">
            <span class="icon" :class="getCssClasses">
                <i class="sj-icon" :class="`icon-${icon}`">
                    <slot></slot>
                </i>
            </span>
            <span class="caption is-flex">{{ caption }}</span>
        </span>
        <span class="icon" :class="getCssClasses" :style="cssProps" v-else>
            <i class="sj-icon" :class="`icon-${icon}`">
                <slot></slot>
            </i>
        </span>
    </div>
</template>

<script>
/**
 * SjIcon Component
 * Seenjab icons wrapper
 * @author Alba IT
 * @version 0.1.0
 */
export default {
    name: 'SjIcon',
    props: {
        /**
         * The Seenjab icon name (without the sj- prefix)
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * The icon size
         * ['small', 'medium', 'large', 'full']
         */
        size: {
            type: String,
            default: 'medium'
        },
        /**
         * The icon align (when used with <input/> tags)
         * ['left', 'right']
         */
        align: {
            type: String,
            default: undefined
        },
        /**
         * Is the icon relative ?
         */
        isRelative: {
            type: Boolean,
            default: false
        },
        /**
         * Extra CSS classes definition
         */
        cssExtraClasses: {
            type: String,
            default: ''
        },
        /**
         * The optionnal icon caption
         */
        caption: {
            type: String,
            default: undefined
        },
        /**
         * The tooltip to show on icon hover
         */
        tooltip: {
            type: String,
            default: ''
        },
        /**
         * The icon color (based on Bulma colors)
         * ['black', 'white', 'primary', 'secondary', 'tertiary', 'success', 'info', 'danger']
         */
        color: {
            type: String,
            default: 'black'
        },
        /**
         * The icon background color
         */
        backgroundColor: {
            type: String,
            default: '#11ffee00'
        },
        /**
         * The icon color on hover
         */
        hoverColor: {
            type: String,
            default: '#000000'
        },
        /**
         * Is the icon clickable?
         */
        isClickable: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    computed: {
        /**
         * CSS dynamic variables
         */
        cssProps: function() {
            return {
                '--width': this.getWrapperSize,
                '--height': this.getWrapperSize,
                '--font-size': this.getIconSize,
                '--color': this.color,
                '--background-color': this.backgroundColor,
                '--hover-color': this.hoverColor,
                '--margin': (typeof this.caption === 'undefined' && typeof this.align === 'undefined') ? '0.25em' : '0',
                '--padding': (this.size === 'full') ? '20px 0' : '0'
            };
        },
        /**
         * Generate the Bulma icon classes according to the component props
         */
        getCssClasses: function() {
            const cssClasses = [{
                'is-clickable': this.isClickable,
                'is-relative': this.isRelative,
                'is-left': this.align === 'left',
                'is-right': this.align === 'right'
            }, this.cssExtraClasses];

            return cssClasses.filter(val => val);
        },
        /**
         * Get the icon size Bulma CSS class according to the component props
         */
        getSizeClass: function() {
            if (this.size === 'default') {
                return '';
            } else {
                return `is-${this.size}`;
            }
        },
        getWrapperSize: function() {
            switch (this.size) {
                case 'small':
                    return '16px';
                case 'medium':
                default:
                    return '32px';
                case 'large':
                    return '64px';
                case 'full':
                    return '100%';
            }
        },
        getIconSize: function() {
            switch (this.size) {
                case 'small':
                    return '12pt';
                case 'medium':
                default:
                    return '20pt';
                case 'large':
                    return '32pt';
                case 'full':
                    return '48pt';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.sj-icon-wrapper {
    background-color: var(--background-color);
}

.sj-icon {
    width: var(--width);
    height: var(--height);
    text-align: center;
    margin-top: 3px;
}

.icon {
    width: var(--width);
    height: var(--height);
    margin-right: var(--margin);
    display: flex;
    //justify-content: center;
    //align-items: center;
}

.icon i {
    width: var(--width);
    height: var(--height);
    color: var(--color);
    font-size: var(--font-size);
    padding: var(--padding);
}

.icon i:hover {
    color: var(--hover-color);
}

.caption {
    line-height: 200%;
    font-family: $font-family !important;
    font-weight: 700;
}
</style>