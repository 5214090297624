/**
 * Data stores middleware
 * @author Alba IT
 * @version 0.1.0
 */
import { createStore, createLogger } from 'vuex';
//import createPersistedState from 'vuex-persistedstate';
import VuexPersistence from 'vuex-persist'
//import localForage from "localforage";
//import Cookies from 'js-cookie'

import documents from '@/middleware/store/stores/documents';
import user from '@/middleware/store/stores/user';

// Create the persistence object
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

/*const vuexIDB = new VuexPersistence({
    storage: localForage,
    asyncStorage: true
});

const vuexCookies = new VuexPersistence({
    restoreState: (key) => Cookies.getJSON(key),
    saveState: (key, state) => Cookies.set(key, state, {
        expires: 3
    })
});*/

const store = new createStore({
    // Data stores modules
    modules: {
        documents,
        user
    },
    // Add the PersistedState plugin and also add a logger when in dev mode
    /*plugins: (process.env.NODE_ENV !== 'production') ?
            [createPersistedState(), createLogger()] :
            [createPersistedState()],*/
    plugins: (process.env.NODE_ENV !== 'production') ?
            [vuexLocal.plugin, createLogger()] :
            [vuexLocal.plugin],
    // Enable strict mode on production
    strict: process.env.NODE_ENV !== 'production'
});

export default store;
