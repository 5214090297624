<template>
    <router-link :to="`/document${url}`">
        <div class="notif">
            <div class="is-flex is-flex-direction-column">
                <div class="is-flex is-flex-direction-row">
                    <div class="is-align-self-center">
                        <img src="../../../assets/images/icons/file-pdf.svg" alt="file-pdf" />
                    </div>
                    <div class="is-flex is-flex-direction-column ml-2">
                        <div>
                            <span class="notification-title has-text-weight-bold is-size-6">{{ title }}</span>
                        </div>
                        <div>
                            <span class="is-uppercase">{{ translatePath(path) }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="has-text-right">
                        {{ $t("message.core.documents.dte_due") }} <span class="has-text-weight-bold">{{ formatDate(new Date(dteDue)) }}</span>
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
/**
 * Notification Component
 * Displays a notification
 * @author Alba IT
 * @version 0.1.0
 */
import { translatePath } from '@/scripts/translation';
import { formatDate } from '@/scripts/util';

export default {
    name: 'Notification',
    props: {
        /**
         * The notification icon
         */
        icon: {
            type: String,
            default: 'bell'
        },
        /**
         * The notification title
         */
        title: {
            type: String,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        /**
         * The notification due date
         */
        dteDue: {
            type: String,
            required: true
        },
        /**
         * The related document/device URL
         */
        url: {
            type: String,
            default: undefined
        }
    },
    computed: {
        translatePath: () => translatePath,
        formatDate: () => formatDate
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.notif {
    //width: 400px;
    background-color: $background-color-2 !important;
    padding: 0 6px;
}

.notification-title {
    white-space: normal;
    word-break: break-all;
}
</style>