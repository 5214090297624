/**
 * Seenjab authentication module
 * @author Alba IT
 * @version 0.1.0
 * @module scripts/auth
 */
import config from '@/config';

import store from '@/middleware/store/store';

import axios from '@/scripts/util';
import UAParser from 'ua-parser-js';

 /**
  * Get fresh access tokens. This function has to be synchronously called before each authenticated request to ensure
  * the availability of a fresh token
  * @returns {Promise} workings tokens or an auth error
  */
export function getToken() {
    // Grab the locally stored token data
    const access_token = store.getters['user/getAccessToken'];
    const refresh_token = store.getters['user/getRefreshToken'];
    const locale = store.getters['user/getLocale'];

    // If an access access_token already exists
    if (typeof access_token !== 'undefined') {
        // Check if it is still valid
        const token_creation = store.getters['user/getTokenCreation'];
        const expires_in = store.getters['user/getExpiresIn'];
        const refresh_expires_in = store.getters['user/getRefreshExpiresIn'];

        /*console.log({
            token_creation: token_creation,
            expires_in: expires_in,
            refresh_expires_in: refresh_expires_in,
            now: new Date().getTime(),
            tokenEnd: new Date(token_creation).getTime() + (expires_in * 1000),
            refreshEnd: new Date(token_creation).getTime() + (refresh_expires_in * 1000),
            cond1: new Date(token_creation).getTime() + (expires_in * 1000) > new Date().getTime(),
            cond2: new Date(token_creation).getTime() + (refresh_expires_in * 1000) > new Date().getTime()
        });*/
        
        // If the current token is still valid
        if (new Date(token_creation).getTime() + (expires_in * 1000) > new Date().getTime()) {
            // Return the current access_token
            return Promise.resolve({
                access_token: access_token,
                refresh_token: refresh_token,
                locale: locale
            });
        // Else if the refresh token is still valid
        } else if (new Date(token_creation).getTime() + (refresh_expires_in * 1000) > new Date().getTime()) {
            // Refresh the token
            return refreshToken(refresh_token)
            .then((result) => {
                // Store the tokens
                store.commit('user/setAccessToken', result.access_token);
                store.commit('user/setExpiresIn', result.expires_in);
                store.commit('user/setRefreshToken', result.refresh_token);
                store.commit('user/setRefreshExpiresIn', result.refresh_expires_in);
                store.commit('user/setTokenCreation', new Date().getTime());

                return result;
            });
        } else {
            return Promise.reject('token-refresh-error', 'The user access token cannot be refreshed, please sign in again.');
        }
    } else {
        return Promise.reject('no-token', 'No access token available.');
    }
        /*return validateToken(access_token)
        .then((result) => {
            if (result.active) {
                // Return the current access_token
                return Promise.resolve({
                    access_token: access_token,
                    refresh_token: refresh_token,
                    locale: locale
                });
            } else {
                // Else, refresh it
                return refreshToken(refresh_token)
                .then((result) => {
                    // Store the tokens
                    store.commit('user/setAccessToken', result.access_token);
                    store.commit('user/setExpiresIn', result.expires_in);
                    store.commit('user/setRefreshToken', result.refresh_token);
                    store.commit('user/setRefreshExpiresIn', result.refresh_expires_in);
                    store.commit('user/setTokenCreation', new Date().getTime());

                    return result;
                });
            }
        });
    } else {
        // Else indicate the token expired and cannot be refreshed
        return Promise.reject('token refresh error', 'The user access token cannot be refreshed, please sign in again.');
    }*/
}
 
 /**
  * Seenjab authentication step 1 call
  * @param {String} username the username
  * @param {String} password the password
  * @returns {Promise}
  */
export function generateOTP(username, password) {
    return axios.post(config.router.url + '/auth', {
        params: {
            'Content-Type': 'application/json'
        },
        username: username,
        password: password
    })
    .then((result) => result.data.data);
}
 
 /**
  * Seenjab authentication setp 2 call
  * @param {String} username 
  * @param {String} otp 
  * @returns {Promise}
  */
export function generateToken(username, otp) {
    return axios.post(config.router.url + '/auth/otp', {
        params: {
            'Content-Type': 'application/json'
        },
        username: username,
        otp: otp
    })
    .then((result) => result.data.data);
}
 
 /**
  * Validate an access_token
  * @param {String} access_token 
  * @returns {Promise}
  */
/*function validateToken(access_token) {
    return axios.post(config.router.url + '/auth/introspect', {
        params: {
            'Content-Type': 'application/json'
        },
        token: access_token
    })
    .then((result) => result.data.data);
}*/
 
 /**
  * Refresh an access_token
  * @param {String} refresh_token 
  * @returns {Promise}
  */
export function refreshToken(refresh_token) {
    return axios.post(config.router.url + '/auth/refresh', {
        params: {
            'Content-Type': 'application/json'
        },
        refresh_token: refresh_token
    })
    .then((result) => result.data.data);
}

 /**
  * Seenjab get QR API call
  * @returns {Promise}
  */
export function getQR() {
    return axios.post(config.router.url + '/auth/qr', {
        params: {
            'Content-Type': 'application/json'
        },
        device_uid: new UAParser().getResult()
    })
    .then((result) => result.data.data);
}

/**
 * Init password reset flow
 * @param {String} email the user email address
 * @returns {Promise}
 */
export function initPasswordReset(email) {
    return axios.post(config.router.url + '/auth/reset/password/init', {
        params: {
            'Content-Type': 'application/json'
        },
        email: email
    })
    .then((result) => result.data.data);
}

/**
 * Reset the password
 * @param {String} token the token provided by the reset initial request and sent by email
 * @param {String} email the user email address
 * @param {String} password the new password for the user
 * @returns {Promise}
 */
export function passwordReset(token, email, password) {
    return axios.post(config.router.url + '/auth/reset/password', {
        params: {
            'Content-Type': 'application/json'
        },
        token: token,
        email: email,
        password: password
    })
    .then((result) => result.data.data);
}

/**
 * Init the PIN code reset flow
 * @param {String} email the user email address
 * @returns {Promise}
 */
 export function initPinReset(email) {
    return axios.post(config.router.url + '/auth/reset/pin/init', {
        params: {
            'Content-Type': 'application/json'
        },
        email: email
    })
    .then((result) => result.data.data);
}

/**
 * Reset the PIN code
 * @param {String} token the token provided by the reset initial request and sent by email
 * @param {String} email the user email address
 * @param {String} pin_code the new PIN code for the user
 * @returns {Promise}
 */
export function pinReset(token, email, pin_code) {
    return axios.post(config.router.url + '/auth/reset/pin', {
        params: {
            'Content-Type': 'application/json'
        },
        token: token,
        email: email,
        pin_code: pin_code
    })
    .then((result) => result.data.data);
}