<template>
    <div id="profile-menu" class="dropdown is-right is-clickable" :class="{'is-active': profileMenuOpen}">
        <div class="dropdown-trigger is-flex is-flex-direction-row is-align-items-center" @click="toggleProfileMenu()" v-click-outside="() => profileMenuOpen = false">
            <figure class="image is-32x32" aria-controls="dropdown-menu-profile">
                <img class="is-rounded" src="../../../assets/images/avatar-anonymous.png" alt="user_picture" />
            </figure>
        </div>
        <div class="dropdown-menu" id="dropdown-menu-profile" role="menu">
            <div class="dropdown-content">
                <router-link to="/profile">
                    <a class="dropdown-item">
                        {{ $t("message.pages.profile.title") }}
                    </a>
                </router-link>
                <hr class="dropdown-divider">
                <a href="#" class="dropdown-item" @click="logout()">
                    {{ $t('navigation.logout') }}
                </a>
            </div>
        </div>
    </div>
    <modal-loader :caption="$t('message.core.common.loading')" ref="loader" />
</template>

<script>
import ModalLoader from '@/components/layout/ModalLoader.vue';

export default {
    name: 'ProfileMenu',
    components: {
        ModalLoader
    },
    data: function() {
        return {
            profileMenuOpen: false
        };
    },
    mounted: function() {
        this.$refs['loader'].isVisible = true;
        //this.$refs['loader'].isLoading = true;
    },
    methods: {
        /**
         * Toggle the profile menu on and off
         * @returns {Void}
         */
        toggleProfileMenu: function() {
            if (this.notificationMenuOpen) this.notificationMenuOpen = false;
            this.profileMenuOpen = !this.profileMenuOpen;
        },
        /**
         * Log the user out and redirect to the login page
         * @returns {Void}
         */
        logout: function() {
            const loader = this.$refs['loader'];
            loader.isLoading = true;

            // Logout the user
            setTimeout(() => {
                this.$store.commit('user/setAccessToken', '');
                this.$store.commit('user/setRefreshToken', '');
                this.$store.commit('user/setExpiresIn', '');
                this.$store.commit('user/setRefreshExpiresIn', ''); 
                this.$store.commit('user/setLogged', false);
                this.$router.push({path: '/login'});

                setTimeout(() => {
                    loader.isLoading = false;
                }, 5000);
            }, 2000);
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-item img {
    max-height: none;
}
</style>