/**
 * The user data store definition
 * @author Alba IT
 * @version 0.1.0
 */

export function initDocumentsObject() {
    return {
        invoices: {
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        insurances: {
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        subscriptions: {
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        taxes: {
            fillings: {
                todo: 0,
                done: 0,
                urgent: 0,
                total: 0
            },
            receipts: {
                todo: 0,
                done: 0,
                urgent: 0,
                total: 0
            },
    
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        user: {
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        trashbin: {
            todo: 0,
            done: 0,
            urgent: 0,
            total: 0
        },
        total: {
            todo: 0,
            done: 0,
            urgent: 0
        }
    };
}

const state = () => ({
    year: new Date().getFullYear(),
    // Sync status 0 = unsync, 1 = sync
    status: 0,
    syncTime: null,
    tags: [],
    documents: initDocumentsObject()
});

// Mutations (sync updates) definitions
const mutations = {
    setReset(state) {
        console.log('reset')
        // acquire initial state
        /*const s = documentObject;
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
        state.documents = s;*/
        state.documents = initDocumentsObject();
    },
    setYear(state, year) {
        state.year = year;
    },
    setStatus(state, status) {
        state.status = status;
    },
    setSyncTime(state, syncTime) {
        state.syncTime = syncTime;
    },
    setTags(state, tags) {
        state.tags = tags;
    },
    setDocuments(state, documents) {
        state.documents = documents;
    }
};

// Actions (async updates) definitions
const actions = {
    setYear(context, data) {
        context.commit('setYear', data.year);
    },
    setStatus(context, data) {
        context.commit('setStatus', data.status);
    },
    setSyncTime(context, data) {
        context.commit('setSyncTime', data.syncTime);
    },
    setTags(context, data) {
        context.commit('setTags', data.tags);
    },
    setDocuments(context, data) {
        context.commit('setDocuments', data.documents);
    }
};

// Getters definitions
const getters = {
    getYear(state) {
        return state.year;
    },
    getStatus(state) {
        return state.status;
    },
    getSyncTime(state) {
        return state.syncTime;
    },
    getTags(state) {
        return state.tags;
    },
    getDocuments(state) {
        return state.documents;
    }/*,
    getInvoices(state) {
        return state.documents.invoices;
    },
    getInsurances(state) {
        return state.documents.insurances;
    },
    getSubscriptions(state) {
        return state.documents.subscriptions;
    },
    getTaxes(state) {
        return state.documents.taxes;
    },
    getTotal(state) {
        return state.documents.total;
    }*/
};

// Export the documents module data store
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
