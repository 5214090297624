<template>
    <div class="control">
        <button class="button mb-0" :class="{'is-loading': isLoading, 'is-fullwidth': isFullwidth}" :style="cssProps" :type="type" @click="$emit('click')" :disabled="disabled">
            <fa-icon class="button-icon" :icon="icon" :color="color" :hoverColor="color" v-if="(icon !== '')" />
            <span>{{ caption }}</span>
        </button>
    </div>
</template>

<script>
/**
 * FormButton component
 * Based on Bulma's buttons
 * @author Alba IT
 * @version 0.1.0
 */
import FaIcon from '@/components/layout/controls/FaIcon.vue';

export default {
    name: 'FormButton',
    components: {
        FaIcon
    },
    props: {
        /**
         * The button type
         */
        type: {
            type: String,
            default: 'button'
        },
        /**
         * The button caption
         */
        caption: {
            type: String,
            default: ''
        },
        /**
         * The button icon
         */
        icon: {
            type: String,
            default: ''
        },
        /**
         * The icon side
         * Values : ['left', 'right']
         */
        iconSide: {
            type: String,
            default: 'left'
        },
        color: {
            type: String,
            default: '#FFFFFF'
        },
        backgroundColor: {
            type: String,
            default: '#D2692E'
        },
        borderColor: {
            type: String,
            default: '#FFFFFF'
        },
        /**
         * Is the button disabled (not clickable) ?
         */
        disabled: {
            type: Boolean,
            default: false
        },
        isFullwidth: {
            type: Boolean,
            dafault: false
        },
        /**
         * The button loading status
         */
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cssProps: function() {
            return {
                '--color': this.color,
                '--background-color': this.backgroundColor,
                '--border-color': this.borderColor
            };
        }
    },
    emits: ['click']
}
</script>

<style lang="scss" scoped>
//@import '../../../../assets/styles/variables.scss';

button,
.button[disabled] {
    margin-bottom: 0.75rem;
    color: var(--color);
    background-color: var(--background-color);
}

button:hover, button:active, button:focus {
    color: var(--color);
}
</style>
