<template>
    <nav class="navbar mb-2 pt-0">
        <div class="navbar-brand">
            <div class="navbar-item ml-2">
                <h1 class="is-size-4 mr-2" :class="{'mt-7': isMobile()}" v-if="path !== '/dashboard'">{{ pageTitle }}</h1>
                <div v-else>
                    <h2 class="is-size-5 ml-3">{{ $t("message.pages.dashboard.welcome") }},<br v-if="isMobile()"/> {{ $store.getters['user/getFirstname'] }} {{ $store.getters['user/getLastname'] }}</h2>
                </div>
            </div>

            <!-- Mobile menu burger -->
            <div id="menu-burger" class="is-flex is-flex-direction-row is-align-items-center is-hidden-desktop has-text-grey">
                <div class="navbar-item">
                    <notification-menu />
                </div>
                <!-- Profile menu -->
                <div class="navbar-item">
                    <profile-menu />
                </div>
                <span class="menu-toggle icon is-clickable" @click="toggleSidebar($event)">
                    <i :class="getBurgerIcon"></i>
                </span>
            </div>
        </div>

        <div id="navbar" class="navbar-menu mr-3">
            <div class="navbar-end">
                <!-- Notifications area -->
                <div class="navbar-item">
                    <!-- Search bar -->
                    <div class="navbar-item">
                        <search-bar v-if="false" />
                    </div>
                </div>
                <div class="navbar-item">
                    <notification-menu />
                </div>
                <!-- Profile menu -->
                <div class="navbar-item">
                    <profile-menu />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
/**
 * NavBar Component
 * Seenjab top navigation menu
 * @author Alba IT
 * @version 0.1.0
 */
import { isMobile } from '@/scripts/util';

import SearchBar from '@/components/layout/controls/SearchBar.vue';
import NotificationMenu from '@/components/layout/menu/NotificationMenu.vue';
import ProfileMenu from '@/components/layout/menu/ProfileMenu.vue';

export default {
    name: 'NavBar',
    components: {
        SearchBar,
        NotificationMenu,
        ProfileMenu
    },
    props: {
        sidebarVisible: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            pageTitle: '',
            path: '',
            burgerOpen: false,
            hiddenMobile: true
        };
    },
    computed: {
        /**
         * Get the burger menu icon according to it's open/closed state for display
         * @returns {String}
         */
        getBurgerIcon: function() {
            //return (!this.hiddenMobile) ? 'fas fa-times' : 'fas fa-bars';
            return (this.sidebarVisible) ? 'fas fa-times' : 'fas fa-bars';
        },
        generateYears: function() {
            const ret = {};
            for (let i = 2021; i <= new Date().getFullYear(); i++) {
                ret[i] = i;
            }
            return ret;
        },
        isMobile: () => isMobile
    },
    /**
     * Get the current route name to display in the NavBar
     * A watch is needed because the view is rendered before the $route instance is updated by vue-router
     * @returns {Void}
     */
    created: function() {
        /*const unwatch =*/ this.$watch(
            () => this.$route,
            (route) => {
                this.path = route.path;
                this.pageTitle = route.name;
                // If a translation is available for the current page title
                if (this.$te(`message.pages.${route.name.toLowerCase()}.title`)) {
                    // Use it as the page title
                    this.pageTitle = this.$t(`message.pages.${route.name.toLowerCase()}.title`);
                }
                
                //unwatch();
            }
        );
    },
    methods: {
        /**
         * Toggle the burger menu on and off (mobile)
         * @returns {Void}
         */
        toggleSidebar: function(event) {
            //this.hiddenMobile = !this.hiddenMobile;
            //this.$emit('sidebar-toggle', this.hiddenMobile);
            // Stop the event propagation in order to prevent the click-outside listeners from triggering
            event.stopPropagation();
            this.$emit('sidebar-toggle', !this.sidebarVisible);
        },
        /**
         * Change the locale
         * @param {String} locale the new locale
         * @returns {Void}
         */
        switchLocale(locale) {
            this.$store.commit('user/setLocale', locale);
            this.$i18n.locale = locale;
        }
    },
    emits: ['sidebar-toggle']
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';
.navbar {
    background-color: $background-color-1;
}
.navbar-item {
    color: #363636 !important;
}

#menu-burger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.8em;
    margin-right: 1em;
    z-index: 60;
}
#menu-toggle {
    z-index: 99;
}
#welcome {
    margin: 0 auto;
}
</style>
