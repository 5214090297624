/**
 * Helper and utility functions collection module
 * @author Alba IT
 * @version 0.1.0
 * @module scripts/util
 */
import axios from 'axios';

import store from '@/middleware/store/store';
import i18n from '@/middleware/i18n/i18n';

/**
 * Format filesize from bytes to a more readable format
 * @param {Number} bytes the unformated bytes to format
 * @param {Number} decimals (optional) the number of decimals to show (default: 2)
 * @returns {String} the formatted file size (1'200 bytes => 1.2 ko / 1'200'000 bytes => 1.2 mo etc etc)
 */
export function formatBytes(bytes, decimals = 2) {
    const byte = i18n.global.tc("message.core.common.units.bytes");
    if (bytes == 0) return `0 ${byte}`;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    //const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const sizes = [
        byte,
        i18n.global.tc("message.core.common.units.kb"),
        i18n.global.tc("message.core.common.units.mb"),
        i18n.global.tc("message.core.common.units.gb"),
        i18n.global.tc("message.core.common.units.tb"),
        'PB', 'EB', 'ZB', 'YB' // No translation needed here for now
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Formate a Date object to a human readable format
 * @param {*} date the Date instance to format
 * @param {*} separator the separator to use (default = '-')
 * @returns {String} the formatted Date
 */
/*export function formatDate(date, separator = '-') {
    // Extract the date parts
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months goe from 0 to 11
    let year = date.getFullYear();

    // Add leading zeroes if needed
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;

    // Return the formatted string
    return year + separator + month + separator + day;
}*/

/**
 * Format a date to a specific locale
 * @param {Date} date the date to format
 * @returns {String} the locally formatted date
 */
export function formatDate(date) {
    const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
    };
    return date.toLocaleDateString(getFullLocale(), options);
}

export function getFullLocale() {
    const locale = store.getters['user/getLocale'];
    if(locale === 'en') {
        return 'en-US';
    } else {
        return `${locale}-${locale.toUpperCase()}`;
    }
}


/**
 * Convert a base64 string into a binary blob by 512 bytes chunks
 * Shamelessly stolen from https://stackoverflow.com/a/16245768
 * @return {blob} the PDF document as a blob
 */
 export function b64toBlob(b64Data, contentType = "application/pdf", sliceSize = 512) {
    // Convert the base64 string into bytes
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    // Loop through the bytes string by 512 bytes chunks
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        // Extract the bytes chunk
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        // Convert the chunk into 8 bytes entries array and push it to the bytes array
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    // Convert the bytes array into a blob object
    const blob = new Blob(byteArrays, {type: contentType});

    // Return the final blob object
    return blob;
}

/**
 * Returns wether the user agent is mobile or not
 * @returns {Boolean}
 */
export function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 1024) {
        return true
    } else {
        return false
    }
}

// Export the Axios instance to be used by the Vue instance
export default axios.create();
