<template>
    <div id="notification-menu" class="dropdown is-right is-clickable mt-2" :class="{'is-active': notificationMenuOpen}">
        <div class="dropdown-trigger" @click="toggleNotificationMenu()" v-click-outside="() => notificationMenuOpen = false" aria-controls="dropdown-menu-notifications">
            <sj-icon icon="bell" color="#4D4F5C" hoverColor="#7e7e7e" size="medium" :isRelative="true" :isClickable="true">
                <span title="Notifications" class="badge is-top-right has-background-danger has-text-weight-semibold has-text-white" :class="{'is-blinking-2': notifications.length > 0}">{{ (typeof notifications.length!== 'undefined') ? notifications.length : 0 }}</span>
            </sj-icon>
        </div>
        <div class="dropdown-menu box p-0" id="dropdown-menu-notifications" role="menu">
            <h2 class="is-size-5 has-text-centered has-text-weight-bold mt-1">{{ $t("message.layout.navbar.notifications") }}</h2>
            <hr class="solid my-2" />
            <notifications-list />
        </div>
    </div>
</template>

<script>
import { getNotifications } from '@/scripts/api/notifications';

import SjIcon from '@/components/layout/controls/SjIcon.vue';
import NotificationsList from '@/components/core/notifications/NotificationsList.vue';

export default {
    name: 'NotificationMenu',
    components: {
        SjIcon,
        NotificationsList
    },
    data: function() {
        return {
            notificationMenuOpen: false,
            notifications: []
        };
    },
    mounted: function() {
        this.$emitter.on('update-notifications', () => {
            this.loadNotifications();
        });
        this.loadNotifications();
    },
    methods: {
        loadNotifications: function() {
            getNotifications()
            .then((notifications) => {
                this.notifications = notifications;
            });
        },
        /**
         * Toggle the notification menu on and off
         * @returns {Void}
         */
        toggleNotificationMenu: function() {
            this.notificationMenuOpen = !this.notificationMenuOpen;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/variables.scss';

.badge {
    height: 18px;
    padding: 0.15rem 0.15rem;
    font-family: $font-family;
    font-size: 10pt;
}

#dropdown-menu-notifications {
    //width: 400px;
    width: 250px;
    height: 300px;
    overflow-y: auto;
}
</style>