/**
 * Sam Web client general configuration
 * @author Alba IT
 * @version 0.1.0
 */

const config = {
    url: (process.env.NODE_ENV === 'development') ? 'http://localhost:8081' : 'https://dashboard.seenjab.com',
    // Router configuration
    router: {
        // Router base URL
        url: (process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/v1' : 'https://router01.seenjab.com/api/v1',
        //auth: (process.env.NODE_ENV === 'development') ? 'ws://router01.seenjab.com:8082' : 'ws://localhost:8082'
        auth: "wss://dashboard.seenjab.com:8082"
    },
    documents: {
        urgent: 10,                                 // Delay (in days) before the Document DTE_DUE for urgent notifications
        updateInterval: 8,                          // Global documents object update interval in hours
        filters: {
            all:'all',
            todo: 'todo',
            urgent: 'urgent',
            done: 'done'
        },
        paths: {
            invoices: 'Documents/Invoices',
            insurances: 'Documents/Insurances',
            subscriptions: 'Documents/Subscriptions',
            taxes: {
                base: 'Documents/Taxes',
                fillings: 'Documents/Taxes/Fillings',
                receipts: 'Documents/Taxes/Receipts'
            },
            user: 'Documents/User',
            trashbin: 'Trashbin'
        }
    },
    user: {
        quota: {                                    // Quotas for account level, in MB
            free: 20,
            premium: 1000
        }
    },
    notifications: {
        firebase: {
            config: {
                apiKey: "AIzaSyAze79cMVgKXaR014uIPbXU6EDQgr4byGI",
                authDomain: "seenjab-5ae00.firebaseapp.com",
                projectId: "seenjab-5ae00",
                storageBucket: "seenjab-5ae00.appspot.com",
                messagingSenderId: "177523242976",
                appId: "1:177523242976:web:1a607ed7e99c4e0ac0dd85"
            },
            vapidKey: 'BATne9RsQbItuNRxVmABDdCUZ_Bx3fZIi3asRs3oszlrydy97JzxcNrzn8S7jZu2ls-j3AAooUt3TQVkX5wYPMw'
        } 
    }
};

export default config;
