/**
 * Seenjab web client
 * @author Alba IT
 * @version 0.1.0
 * @todo Better refresh for notifications/docs counters
 */
// Vue
import { createApp } from 'vue';
import App from './App.vue';

// Libraries
import router from '@/middleware/router';
import store from '@/middleware/store/store';
import i18n from '@/middleware/i18n/i18n';
import axios from '@/scripts/util';
import mitt from 'mitt';
import VueAxios from 'vue-axios';
import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga-full.css';
import '../node_modules/nprogress/nprogress.css';

// Styles
require('@/assets/styles/style.scss');
require('@/assets/fonts/seenjab-icons/seenjab-icons.css');
require('/vendors/fontawesome/css/all.min.css');

// Firebase
//import firebase from '@/middleware/firebase';

// Init the root component
const app = createApp(App);
app.use(router)
.use(store)
.use(i18n)
.use(VueAxios, axios)
.use(Oruga, {
    iconPack: 'fa'
});

const emitter = mitt();
app.config.globalProperties.$emitter = emitter;
//app.config.globalProperties.$firebase = firebase;

/**
 * v-click-outside
 * Click outside of component event handler directive
 * Usage <elment v-click-outside="() => //[...]"
 */
app.directive('click-outside', {
    beforeMount: function(el, binding) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

// Mount the app
app.mount('#app');

// Uncomment this for debugging in production
//app.config.devtools = true;
