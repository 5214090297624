/**
 * Seenjab Users endpoints module
 * @author Alba IT
 * @version 0.1.0
 * @module scripts/user
 */
import config from '@/config';

import store from '@/middleware/store/store';

import axios from '@/scripts/util';

/**
 * Get the current user properties
 * @returns {Promise}
 */
export function getProfile() {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/users/${username}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => result.data.data);
}

/**
 * Update the current User profile
 * @param {Object} profile the user profile data object
 * @returns {Promise}
 */
export function updateProfile(profile) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.put(config.router.url + `/users/${username}`, {
        firstname: profile.firstname,
        lastname: profile.lastname,
        email: profile.email,
        password: profile.password,
        locale: profile.locale
    },
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
    .then((result) => result.data.data);
}

/**
 * Create a new user profile
 * @param {Object} profile the user profile data object
 * @returns {Promise}
 */
export function createProfile(profile) {
    // Call the Seenjab remote endpoint
    return axios.post(config.router.url + '/users', {
        username: profile.username,
        firstname: profile.firstname,
        lastname: profile.lastname,
        email: profile.email,
        password: profile.password,
        locale: profile.locale
    },
    {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((result) => result.data.data);
}

export function validateAccount(validationKey) {
    return axios.get(config.router.url + `/users/validate/${validationKey}`, {},
    {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
