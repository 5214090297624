/**
 * Localization middleware
 * @author Alba IT
 * @version 0.1.0
 */
import { createI18n } from 'vue-i18n/index';

// Import the localization french dictionaries
import * as message_fr from '@/middleware/i18n/locales/message/fr.json';
import * as navigation_fr from '@/middleware/i18n/locales/navigation/fr.json';

// Import the localization english dictionaries
import * as message_en from '@/middleware/i18n/locales/message/en.json';
import * as navigation_en from '@/middleware/i18n/locales/navigation/en.json';

// Import the localization german dictionaries
import * as message_de from '@/middleware/i18n/locales/message/de.json';
import * as navigation_de from '@/middleware/i18n/locales/navigation/de.json';

// Build the internationalization object using the external .json localization dictionaries
const messages = {
    fr: {
        message: message_fr.default,
        navigation: navigation_fr.default
    },
    en: {
        message: message_en.default,
        navigation: navigation_en.default
    },
    de: {
        message: message_de.default,
        navigation: navigation_de.default
    }
};

// Build the i18n instance
const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages
});

export default i18n;
