/**
 * Seenjab translation helpers
 * @author Alba IT
 * @version 0.1.0
 * @module scripts
 */
import i18n from '@/middleware/i18n/i18n';

/**
 * Translates a document path base directories
 * @param {String} path the path to translate
 * @returns {String} the translated path
 */
export function translatePath(path) {
    let translatedPath = [];
    // Get the meaningful path part
    let splitContext;
    if (path.split('/').length > 1) {
        splitContext = path.substring(1).split('/');
        // Remove the residual empty entries
        splitContext = splitContext.filter(item => item);
    } else {
        splitContext = [path];
    }
    
    // Loop through the path and translate the path parts
    for (let i = 0; i < splitContext.length; i++) {
        // If a translation exist for the current path parth (mostly standard directories)
        if (i18n.global.te(`message.core.documents.directories.${splitContext[i]}`)) {
            // Push the translated part in the translated context
            translatedPath.push(i18n.global.tc(`message.core.documents.directories.${splitContext[i]}`));
        } else {
            // Else, push the context part as is
            translatedPath.push(splitContext[i]);
        }
    }

    if (path.split('/').length > 1) {
        return translatedPath.join('/');
    } else {
        return translatedPath.join(',');
    }
}
