<template>
    <!-- Layout component -->
    <!--<layout />-->
    <layout v-if="$store.getters['user/isLogged'] && $route.meta.auth" />
    <public-layout v-else />
</template>

<script>
/**
 * Seenjab Web Root (main) component
 * @author Alba IT
 * @version 0.1.0
 */
//import config from '@/config';

import { getToken } from '@/scripts/api/auth';

import Layout from '@/components/layout/Layout.vue';
import PublicLayout from '@/components/layout/PublicLayout.vue';

export default {
	name: 'App',
	components: {
        Layout,
        PublicLayout
	},
    /**
     * Do main configuration and data synchronization
     * @returns {Void}
     */
    beforeMount: function() {
        // Axios configuration
		this.configureAxios();

        // Set the i18n locale
        const locale = (this.$store.getters['user/getLocale'] !== '') ? this.$store.getters['user/getLocale'] : 'fr';
        this.$i18n.locale = locale;
    },
    methods: {
        /**
         * Configure the Axios HTTP client interceptors
         * Interceptors allows to intercept HTTP requests at various level
         * Seenjab uses a request interceptor allowing the injection of a fresh access token as long as a valid refresh token is available
         * @returns {Void}
         */
        configureAxios: function() {
            this.$http.interceptors.request.use(
                async config => {
                    const isLogged = this.$store.getters['user/isLogged'];
                    // If the user is logged and the current request is NOT a token refresh request
                    // Calling this interceptor on a token refresh request WILL create an infinite loop
                    if (isLogged && !config.url.includes('/auth/refresh')) {
                        try {
                            // Attempt to get a fresh token
                            const tokens = await getToken();
                            // Add the access token to the headers
                            config.headers['Authorization'] = `Bearer ${tokens.access_token}`;
                            config.headers['Accept'] = "application/json";
                        } catch(error) {
                            // Logout the user
                            this.$store.commit('user/setAccessToken', '');
                            this.$store.commit('user/setRefreshToken', '');
                            this.$store.commit('user/setExpiresIn', '');
                            this.$store.commit('user/setRefreshExpiresIn', '');
                            this.$store.commit('user/setLogged', false);

                            setTimeout(() => {
                                this.$router.push({path: '/login'});
                                Promise.reject(error);
                            }, 1000);
                        }
                    }

                    return config;
                },
                error => {
                    Promise.reject(error)
                }
            );
            this.$http.interceptors.response.use(
                (response) => {
                    // No need to fiddle with the response if no error was returned
                    return response;
                },
                (error) => {
                    if (error.response.status === 503) {
                        this.$router.push({path: '/maintenance'});
                    }
                    return Promise.reject(error);
                }
            );
        }
    }
}
</script>
