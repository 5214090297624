/**
 * Seenjab Cloud module
 * @author Alba IT
 * @version 0.1.0
 * @module scripts/document
 */
import config from '@/config';

import store from '@/middleware/store/store';
import { initDocumentsObject } from '@/middleware/store/stores/documents';

import { getProfile } from '@/scripts/api/user';
import { getPremiumStatus } from '@/scripts/api/payments';

import axios, { b64toBlob } from '@/scripts/util';

/**
 * Get a documents list from a remote path
 * @param {String} path the remote path
 * @param {String} depth the DAV depth option [1..n, 'infinity'] (default = 1)
 * @returns {Promise}
 */
export function getDocuments(path, depth = '1') {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Remove the trailing slash causing issues with CORS preflight requests if necessary
    if (path.substring(path.length - 1) === '/') {
        path = path.slice(0, path.length - 1);
    }

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/documents/${username}${path}?depth=${depth}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then((result) => result.data.data);
}

/**
 * Builds a Tree object of the user documents to use in the TreeBranch component
 * @param {String} root the Tree object root path (default = '/')
 * @param {Boolean} displayFiles also display the files
 * @returns {Promise<Array>} the tree branches in a tree
 */
export function buildDocumentsTree(root = '/', displayFiles = true) {
    // Request the document list for path
    return getDocuments(root)
        .then(async (result) => {
            // Define the current branch
            const splitPath = root.split('/').filter((val) => val);
            const branch = {
                name: splitPath[splitPath.length - 1],
                path: root,
                icon: "folder",
                children: []
            };

            // Loop through the path content starting from index 1
            // Index 0 represents the *current* path, processing it here would end up in an infinite loop
            for (let i = 1; i < result.length; i++) {
                const entry = result[i];

                // If the content is a directory
                if (entry.isDirectory) {
                    // Call the current function recursively on entry.path and push the result into the current branch children
                    /*buildDocumentsTree(entry.path, displayFiles)
                    .then((result) => branch.children.push(result));*/
                    branch.children.push(await buildDocumentsTree(entry.path, displayFiles));
                } else if (displayFiles) {
                    // Else, push the childless branch into the tree object
                    branch.children.push({
                        name: entry.name,
                        path: `${entry.path}/${entry.name}`,
                        icon: "file",
                        children: []
                    });
                }
            }

            // Finally, return the built tree as a Promise result
            return Promise.resolve(branch);
        });
}


/**
 * Get the Documents tags list from the /Documents API
 * @returns {Promise}
 */
export function getTags() {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/documents/tags/${username}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then((result) => result.data.data);
}

/**
 * Get a documents list from a remote path with a specifig tag
 * @param {Number} tag the Tag ID
 * @param {String} path (optional) the remote path
 * @param {String} depth the DAV depth option [1..n, 'infinity'] (default = 1)
 * @returns {Promise}
 */
export function getDocumentsFromTag(tag, path = null, depth = '1') {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    if (path === '/') path = '';

    let endpoint = `/documents/tags/${username}/${tag}?depth=${depth}`;
    if (path !== null) {
        endpoint = `/documents/tags/${username}/${tag}/${path}?depth=${depth}`;
    }
    // Remove the trailing slash causing issues with CORS preflight requests if necessary
    if (endpoint.substring(endpoint.length - 1) === '/') {
        endpoint = endpoint.slice(0, endpoint.length - 1);
    }

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + endpoint, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then((result) => result.data.data);
}

/**
 * Get the list of urgent documents since it is a dynamic state, we cannot use tags directly but must calculate it
 * 1. Get all "Todo" documents
 * 2. Exclude documents where STATUS > 1 || Current_date - DUE_DATE > 10
 * @param {String} path (optionnal) the document path
 * @returns {Promise} the urgent documents list
 */
export function getUrgentDocuments(path = '/') {
    // Get all todo documents
    const tag = store.getters['documents/getTags']['todo'];
    return getDocumentsFromTag(tag, path)
        .then((result) => {
            const ret = [];
            // Loop through the result array
            for (let i = 0; i < result.length; i++) {
                // If the document is not urgent
                /*if (!isUrgent(result[i])) {
                    // Remove it from the result array
                    result.splice(i, 1);
                }*/
                // If the document is urgent, add it to the return Array
                if (isUrgent(result[i])) ret.push(result[i]);
            }
            // Return the urgent documents list
            return Promise.resolve(ret);
        });
}

/**
 * Get the list of done documents since it is a dynamic state, we cannot use tags directly but must calculate it
 * 1. Get all the documents
 * 2. Exclude documents where STATUS < 2
 * @param {String} path (optionnal) the document path
 * @param {String} depth the DAV depth option [1..n, 'infinity'] (default = 1)
 * @returns {Promise} the done documents list
 */
export function getDoneDocuments(path = '/', depth = '1') {
    // Get all the documents
    return getDocuments(path, depth)
        .then((result) => {
            const ret = [];

            // Loop through the result array
            for (let i = 0; i < result.length; i++) {
                // If the document is done, add it to the return Array
                if (isDone(result[i])) ret.push(result[i]);
            }

            // Return the done documents list
            return Promise.resolve(ret);
        });
}

/**
 * Create a new directory
 * @param {String} path the directory path
 * @returns {Promise} the response from the server
 */
export function addDirectory(path) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Encode the path elements individually to preserve URI identifiers (RFC 3986 1.2.3)
    const encodedPath = path.split('/').map(e => encodeURIComponent(e)).join('/');

    // Call the Seenjab remote endpoint
    return axios.request({
        url: config.router.url + `/documents/${username}${encodedPath}`,
        method: 'MKCOL',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    }).then((result) => result.data.data);
}

/**
 * Move a document or directory
 * @param {String} path the path to move
 * @param {String} destination the path destination
 * @param {Boolean} overwrite overwrite the destination if it exists
 * @returns {Promise} the response from the server
 */
export function move(path, destination, overwrite = false) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Encode the path and destination elements individually to preserve URI identifiers (RFC 3986 1.2.3)
    const encodedPath = path.split('/').map(e => encodeURIComponent(e)).join('/');
    const encodedDestination = destination.split('/').map(e => encodeURIComponent(e)).join('/');

    // Call the Seenjab remote endpoint
    return axios.request({
        url: config.router.url + `/documents/${username}${encodedPath}`,
        method: 'MOVE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
            'Destination': encodedDestination
        },
        data: {
            overwrite: overwrite
        }
    }).then((result) => result.data.data);
}

/**
 * Permanently delete a document
 * @param {String} path the path to delete
 * @returns {Promise} the response from the server
 */
export function deleteDocument(path) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Encode the path and destination elements individually to preserve URI identifiers (RFC 3986 1.2.3)
    const encodedPath = path.split('/').map(e => encodeURIComponent(e)).join('/');

    // Call the Seenjab remote endpoint
    return axios.request({
        url: config.router.url + `/documents/${username}${encodedPath}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    }).then((result) => result.data.data);
}

/**
 * Restore a document or directory
 * @param {String} path the path to move restore
 * @returns  the response from the server
 */
export function restore(path) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Encode the path and destination elements individually to preserve URI identifiers (RFC 3986 1.2.3)
    const encodedPath = path.split('/').map(e => encodeURIComponent(e)).join('/');

    // Call the Seenjab remote endpoint
    return axios.request({
        url: config.router.url + `/trash/restore/${username}${encodedPath}`,
        method: 'MOVE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    }).then((result) => result.data.data);
}

/**
 * Get a remote document content
 * @param {String} path the remote path
 * @returns {Promise} the document content
 */
export function getDocumentContent(path) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Call the Seenjab remote endpoint
    return axios.get(config.router.url + `/documents/content/${username}${path}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then((result) => result.data.data);
}

/**
 * Upload a document
 * @param {String} path the remote path
 * @param {File} file the document to upload
 * @param {Boolean} ocr request the OCR service
 * @returns 
 */
export async function addFile(path, file, ocr = false) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Remove the trailing slash causing issues with CORS preflight requests if necessary
    if (path.substring(path.length - 1) === '/') {
        path = path.slice(0, path.length - 1);
    }

    // Extract the file content
    let fileContent;
    try {
        fileContent = await getFileContent(file);
    } catch (err) {
        return Promise.reject(err);
    }

    // Call the Seenjab remote endpoint
    return axios.post(config.router.url + `/documents/${username}${path}`, {
        filename: file.name,
        type: file.type,
        content: fileContent.replace(`data:${file.type};base64,`, ""),
        ocr: ocr
    },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then((result) => result.data.data);
}

/**
 * Update a filemetadata
 * @param {String} path the file relative path
 * @param {*} metadata the metadata object
 * @returns {Promise} the request result
 */
export function updateFile(path, metadata) {
    // Grab the username and access_token from the local data store
    const username = store.getters['user/getUsername'];
    const access_token = store.getters['user/getAccessToken'];

    // Remove the trailing slash causing issues with CORS preflight requests if necessary
    if (path.substring(path.length - 1) === '/') {
        path = path.slice(0, path.length - 1);
    }

    // Call the Seenjab remote endpoint
    return axios.put(config.router.url + `/documents/${username}${path}`, {
        meta_data: metadata
    },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            }
        })
        .then((result) => result.data.data);
}

/**
 * Extract the file content before sending it the the upload REST endpoint
 * @param {File} file the file to extract
 * @returns {Promise}
 */
function getFileContent(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const b64str = `${event.target.result}`;
            resolve(b64str);
        };
        reader.onerror = (error) => reject(error);

        reader.readAsDataURL(file);
    });
}

/**
 * Generate the Tags list from the /Document API
 * @returns {Promise}
 */
export function generateTagsList() {
    return getTags()
        .then((result) => {
            const ret = {};
            // Loop through the tags and add them to the tags list object
            result.forEach((entry) => {
                const name = entry['display-name'];
                ret[name] = entry.id;
            });
            return ret;
        });
}

/**
 * Generates a documents list according to params
 * @param {String} filter the filter (may or may not be a tag!)
 * @param {String} path the document's path
 * @param {String} depth the DAV depth option [1..n, 'infinity'] (default = 1)
 * @returns {array} the documents list
 */
export async function generateDocumentsList(filter, path, depth = '1') {
    // Get the tag id from the filter param
    const tag = store.getters['documents/getTags'][filter];

    // Get the documents list according to the filter param
    let documents = [];
    switch (filter) {
        case 'all':
            // If the filter is "all", call the getDocuments() function to get documents regardless of tags
            documents = await getDocuments(path, depth);
            // Remove the first entry that represents the current directory
            documents.shift();
            break;
        case 'done':
            // If the filter is "done", call the getDoneDocuments() function since it's a dynamic state it has to be processed
            documents = await getDoneDocuments(path, depth);
            // Remove the first document if it is a directory (representing the current directory by Nextcloud)
            if (documents.length > 0) {
                if (documents[0].isDirectory) documents.shift();
            }
            break;
        case 'urgent':
            // If the filter is "urgent", call the getUrgentDocuments() function since it's a dynamic state it has to be processed
            documents = await getUrgentDocuments(path);
            break;
        default:
            // Else, call the getDocumentsFromTag() function
            documents = await getDocumentsFromTag(tag, path.substring(1));
    }

    // Assign the documents list to the component
    return filterDocuments(filter, documents);
}

/**
 * Filter the documents
 * @param {String} filter the filter to use
 * @param {String} documents the docments from the API
 * @returns {array} the filtered documents list
 */
export function filterDocuments(filter, documents) {
    const ret = [];

    // Loop through the document entries
    documents.forEach((entry) => {
        // If the document is not a directory
        if (/*!entry.isDirectory &&*/ entry.STATUS != 0) {
            //if (entry.STATUS != 0) {
            switch (filter) {
                // all : return all documents from the path
                case config.documents.filters.all:
                    ret.push(entry);
                    break;
                case config.documents.filters.todo:
                    if (entry.STATUS < 2) ret.push(entry);
                    break;
                case config.documents.filters.urgent:
                    if (entry.STATUS < 2 && isUrgent(entry)) ret.push(entry);
                    break;
                case config.documents.filters.done:
                    if (entry.STATUS == 2) ret.push(entry);
            }
        }
    });

    // Return the filtered documents list
    return ret;
}

/**
 * Generate a JSON object containing basic document informations
 * @param {String} tag the document tag to retrieve informations for
 * @returns {Promise} the document informations Object
 */
export function generateDocumentsInformations(tag) {
    let documents;
    // Get the document list
    if (typeof tag !== 'undefined') {
        //documents = getDocumentsFromTag(tag, '/Documents');
        documents = getDocumentsFromTag(tag);
    } else {
        documents = getDocuments('/Documents', 'infinity');
    }

    // Parse the doucment list into an information object
    return documents.then((result) => {
        // Static document object definition imported from the document store middleware to avoid duplication
        const documents = initDocumentsObject();

        // Loop through the API response results
        result.forEach((entry) => {
            const path = entry.path.substring(1);
            // If the document is not a directory
            if (!entry.isDirectory) {
                // Compare the path and add the necessary informations
                switch (path) {
                    case config.documents.paths.invoices:
                        //documents.invoices.documents.push(entry);
                        if (isToDo(entry)) documents.invoices.todo++;
                        if (isUrgent(entry)) documents.invoices.urgent++;
                        if (entry.STATUS == 2) documents.invoices.done++;
                        documents.invoices.total++;
                        break;
                    case config.documents.paths.subscriptions:
                        //documents.subscriptions.documents.push(entry);
                        if (isToDo(entry)) documents.subscriptions.todo++;
                        if (isUrgent(entry)) documents.subscriptions.urgent++;
                        if (entry.STATUS == 2) documents.subscriptions.done++;
                        documents.subscriptions.total++;
                        break;
                    case config.documents.paths.insurances:
                        //documents.insurances.documents.push(entry);
                        if (isToDo(entry)) documents.insurances.todo++;
                        if (isUrgent(entry)) documents.insurances.urgent++;
                        if (entry.STATUS == 2) documents.insurances.done++;
                        documents.insurances.total++;
                        break;
                    case config.documents.paths.taxes.fillings:
                        //documents.taxes.fillings.documents.push(entry);
                        if (isToDo(entry)) documents.taxes.fillings.todo++;
                        if (isUrgent(entry)) documents.taxes.fillings.urgent++;
                        if (entry.STATUS == 2) documents.taxes.fillings.done++;
                        documents.taxes.fillings.total++;
                        break;
                    case config.documents.paths.taxes.receipts:
                        //documents.taxes.receipts.documents.push(entry);
                        if (isToDo(entry)) documents.taxes.receipts.todo++;
                        if (isUrgent(entry)) documents.taxes.receipts.urgent++;
                        if (entry.STATUS == 2) documents.taxes.receipts.done++;
                        documents.taxes.receipts.total++;
                        break;
                    // Various path
                    case config.documents.paths.user:
                        //documents.user.documents.push(entry);
                        if (isToDo(entry)) documents.user.todo++;
                        if (isUrgent(entry)) documents.user.urgent++;
                        if (entry.STATUS == 2) documents.user.done++;
                        documents.user.total++;
                        break;
                    /*case config.documents.paths.trashbin:
                        documents.trashbin.total++;
                        break;*/
                    default: {
                        // Get the user defined top-level directory name
                        const splitPath = path.split('/');
                        const name = splitPath[splitPath.length - 1];

                        // In case of an user difined top-level directory, create a new object entry if it doesn't exist yet
                        if (typeof documents[name] === 'undefined') {
                            documents[name] = {
                                todo: 0,
                                urgent: 0,
                                done: 0,
                                total: 0
                            };
                        }

                        // Count the documents in the user defined top-level directory
                        if (isToDo(entry)) {
                            documents[name].todo++
                        }
                        if (isUrgent(entry)) {
                            documents[name].urgent++
                        }
                        if (entry.STATUS == 2) {
                            documents[name].done++
                        }
                        documents[name].total++;

                        break;
                    }
                }

                // Increment the sub-totals
                documents.taxes.todo = documents.taxes.fillings.todo + documents.taxes.receipts.todo;
                documents.taxes.done = documents.taxes.fillings.done + documents.taxes.receipts.done;
                documents.taxes.urgent = documents.taxes.fillings.urgent + documents.taxes.receipts.urgent;
                documents.taxes.total = documents.taxes.fillings.total + documents.taxes.receipts.total;

                // Increment the total
                if (isToDo(entry) && path !== config.documents.paths.trashbin) documents.total.todo++;
                if (isUrgent(entry) && path !== config.documents.paths.trashbin) documents.total.urgent++;
            } else {
                // Prepare the user defined top-level directories
                const splitPath = path.split('/').filter(e => e);
                const name = splitPath[splitPath.length - 1];

                if (!(name.toLowerCase() in config.documents.paths)) {
                    documents[name] = {
                        todo: 0,
                        urgent: 0,
                        done: 0,
                        total: 0
                    };
                }
            }
        });
        
        // Return the document informations object
        return Promise.resolve(documents);
    })
    .then(async (documents) => {
        // Get the Trashbin documents and count them
        const trashbinDocuments = await getDocuments('/Trashbin', 'infinity');
        trashbinDocuments.forEach((trashbinDocument) => {
            if (!trashbinDocument.isDirectory) {
                if (isToDo(trashbinDocument)) documents.trashbin.todo++;
                if (isUrgent(trashbinDocument)) documents.trashbin.urgent++;
                if (trashbinDocument.STATUS == 2) documents.trashbin.done++;
                documents.trashbin.total++
            }
        });

        // Return the document informations object with Trashbin content
        return Promise.resolve(documents);
    });
}

/**
 * Generates the documents informations stores (tags and document informations)
 * @returns {Promise<void>}
 */
export function updateLocalStore() {
    // Synchronize the tags list
    return generateTagsList()
        .then((result) => {
            store.commit('documents/setTags', result);
            //const todo = store.getters['documents/getTags']['todo'];
            return generateDocumentsInformations();
        })
        .then((result) => {
            store.commit('documents/setDocuments', result);
            store.commit('documents/setStatus', 1);
            store.commit('documents/setSyncTime', new Date());

            return getProfile();
        })
        .then((result) => {
            // Store the user profile data
            store.commit('user/setFirstname', result.iam_profile.firstname);
            store.commit('user/setLastname', result.iam_profile.lastname);
            store.commit('user/setLocale', result.iam_profile.locale);
            store.commit('user/setEmail', result.iam_profile.email);
            store.commit('user/setQuota', result.cloud_profile.quota.total);
            store.commit('user/setUsedQuota', result.cloud_profile.quota.used);

            // Get the user premium status
            return getPremiumStatus();
        })
        .then((result) => {
            store.commit('user/setPremiumStatus', result);
        });
}

/**
 * Get the document current status
 * @param {Object} document the document from the API
 * @returns {String} the status code
 */
export function getStatus(document) {
    if (isToDo(document)) {
        if (isUrgent(document)) {
            return "urgent";
        } else {
            return "todo";
        }
    } else {
        return "done";
    }
}

/**
 * Has the document been processed already ?
 * @param {Object} document the document Object from the API
 * @returns {Boolean} the document processed status
 */
function isToDo(document) {
    return document.STATUS < 2;
}

/**
 * Is the document in urgent status ?
 * @param {Object} document the document Object from the API
 * @returns {Boolean} urgent status
 */
export function isUrgent(document) {
    if (document.DTE_DUE === null) return false;
    // Calculate the delay before DTE_DUE for urgent notifications in milliseconds
    const urgentDelay = config.documents.urgent * 24 * 60 * 60 * 1000;

    // Calculate the diff between now and DTE_DUE
    const dteDue = new Date(document.DTE_DUE);
    const dteDiff = dteDue.getTime() - Date.now();

    // If the delta is lower than the notification delay and the document status < 2
    if (dteDiff <= urgentDelay && document.STATUS < 2) {
        // The document is urgent
        return true;
    } else {
        // The document is not urgent
        return false;
    }
}

/**
 * Is the document in done status ?
 * @param {Object} document the document object from the API
 * @returns {Boolean} done status
 */
export function isDone(document) {
    return document.STATUS >= 2;
}

/**
 * Get a document's content and return it to the browser as a BLOB
 * @param {Object} document the Document instance to download
 * @returns {Void}
 */
export function download(document) {
    // Get the document base64 content from the API
    getDocumentContent(`${document.path}/${document.name}`)
        .then((result) => {
            // Convert the base64 document into a binary blob
            const blob = b64toBlob(result, "application/pdf");
            // Create a blob URL
            const blobUrl = URL.createObjectURL(blob);

            // Create a temporary link in order to set a meaningful filename for download
            const link = window.document.createElement('a');
            link.href = blobUrl;
            link.download = (document.DOC_NAME !== null) ? `${document.DOC_NAME}.pdf` : document.name;
            link.click();

            // Open the blob URL into a new tab
            //window.open(blobUrl, '_blank').focus();
        });
}
